/**
 * ヘッダー用モジュール。
 * @module app/view/Header
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'dojo/topic',
    'dojo/text!./templates/TrainingHeader.html',
    'dojox/widget/Toaster',
    'dijit/registry',
    'idis/util/DateUtils',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/IdisDialog',
    'idis/view/form/MunicipalitySelectForm',
    'app/view/PopupAlert',
    // 以下、変数で受けないモジュール
    'dijit/form/Select',
    'idis/view/form/GeocodingForm',
    'dijit/form/Form',
    'app/view/Menu',
    'app/view/form/DisasterChanger',
    'app/common/notifier/MainNotifier'
], function(module, array, declare, lang, domStyle, on, topic, template, Toaster,
    registry, DateUtils, Locator, Router, UserInfo, _IdisWidgetBase) {
    /**
     * ヘッダー用オブジェクト
     * @class Header
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, /** @lends app/view/Header~Header# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'index-Header',

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            this.loginIdNode.innerHTML = UserInfo.getName();

            var html = [];
            // ログアウト・フォーム
            html.push('<form method="POST" action="/api/auth/logout" name="logout" style="padding: 2px;">');
            // 戻り先を設定（現在のURLのpath部分）
            html.push('<input type="hidden" name="backPath" value="');
            html.push(Locator.getHref().split('?')[0]);
            html.push('">');
            html.push('<a href="javascript:document.logout.submit()">');
            html.push('ログアウト</a></form>');

            // リンク一覧の中身を置き換える
            this.logoutButton.innerHTML = html.join('');

            if (UserInfo.isAuthed()) {
                // 認証済みの場合
                // メニュー用ボタンを表示
                domStyle.set(this.menuButton, 'display', '');
                // 右上の各要素を表示
                // ヘッダコントロールを表示
                domStyle.set(this.controlHeader, 'display', '');

                if (UserInfo.getLoginMode() === '訓練') {
                    // 訓練
                    domStyle.set(this.disaster, 'display', 'none');
                    domStyle.set(this.training, 'display', '');
                } else {
                    // 実災害
                    domStyle.set(this.disaster, 'display', '');
                    domStyle.set(this.training, 'display', 'none');
                }
                domStyle.set(this.modeArea, 'display', '');

                domStyle.set(this.controlArea, 'display', '');
            }
        },

        // DOMの準備が完了してから呼ばれる
        postCreate: function() {
            this.inherited(arguments);
        },

        startup: function(){
            this.inherited(arguments);
            // 災害名登録がPubされた際にヘッダーの災害名を切り替える
            this.own(topic.subscribe('app/view/form/DisasterSelector::registered',
                lang.hitch(this, function(payload) {
                    this.disasterSelector._setValueAttr(payload);
            })));
            // this.own(topic.subscribe(module.id + '::notifier', lang.hitch(this, this.startNotifier)));
            this.notifier.start();
        },

        startNotifier: function(){
            this.notifier.start();
        },

        /**
         * タイトル文字列がクリックされた際に呼ばれ、トップ画面へ遷移させる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onTitleLinkClick: function(evt) {
            // TODO トップ画面・地図画面ではタイトルのリンクを無効化した方が良い
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // トップ画面以外にいる場合はトップ画面（通常は地図画面）へ遷移
            if (Locator.getQuery().p) {
                // ページへ遷移
                Locator.pushState('?', true);
            }
        }
    });
});
