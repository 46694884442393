/**
 * Main画面用の通知制御
 * @module app/common/notifier/MainNotifier
 */

define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/json',
    'dojo/date/locale',
    'dojo/topic',
    'dojo/dom-style',
    'idis/consts/STORAGE_KEY',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/util/storage/SessionStorage',
    'app/model/DisasterInfo',
    './Notifier',
    '../../config',
    'idis/consts/USER_TYPE',
    'app/broadnotify/BroadnotifyReceiveDialog'
], function (module, declare, lang, array, JSON, locale, topic, domStyle, StorageKey, UserInfo,
    Requester, Storage, DisasterInfo, Notifier, config, USER_TYPE) {
    return declare(module.id.replace(/\//g, '.'), Notifier, {
        THRESHOLD_GATHER: 5, // 表示させるべきクロノロジ・被害を集約して表示する閾値
        SHOW_DURATION: 30, // 通知を表示させておく時間(s)
        REQUEST_INTERVAL: 1, //新着クロノロジ・被害の検索APIをよびだすインターバルの時間(min)
        TIMELINE_REQUEST_INTERVAL: 5, // タイムラインの検索APIをよびだすインターバルの時間(min)
        MAX_CONTENT_LENGTH: 60, //新着クロノロジ・被害の内容に表示する文章の最大文字数
        TIMELINE_GATHER: 2, // 表示させるべきタイムラインを集約して表示する閾値
        TIMELINE_SHOW_DURATION: 30, // 通知を表示させておく時間(s)
        BROADNOTIFY_INTERVAL: 60, // 緊急通知インターバルの時間

        timer: null,

        // timelineTimer: null,

        audioData: [],

        event: 'app/view/form/DisasterChanger::changed',

        /**
         * widget開始時に呼ばれるもの, 情報取得などやる
         */
        startup: function () {
            this.inherited(arguments);

            // 所属組織を取得
            this.organizationCd = UserInfo.getOrganization();

            // 緊急通知のaudioデータをスタートアップ時に取得し流せるように準備する。
            if(this.audioData.length === 0){
                this.audioData.push(new Audio('/data/sound/sound1.mp3'));
                this.audioData.push(new Audio('/data/sound/sound2.mp3'));
                this.audioData.push(new Audio('/data/sound/sound3.mp3'));
                this.audioData.forEach(lang.hitch(this, function(a){
                    a.load();
                }));
            }
        },

        /**
         * DOM生成
         */
        buildRendering: function () {
            this.inherited(arguments);
            // 災害名が変更された時に実施
            topic.subscribe(this.event, lang.hitch(this, function (id) {
                this.changeDisaster(id);
            }));
        },


        /**
         * 災害名変更
         */
        changeDisaster: function (id) {
            this._disasterId = String(id);
        },

        /**
         * widgetの動作を開始するためのfunction. これを呼び出すと画面に通知し始める
         */
        start: function () {
            if (UserInfo.getId()) {
                var regexp = /^(?!.*-c).*(?=honbu).*$/;
                if(regexp.test(UserInfo.getId())) {
                // if(UserInfo.getRoleCd() === 'R02012' || UserInfo.getRoleCd() === 'R02022' ||
                //     UserInfo.getRoleCd() === 'R02032') {
                    this.initReceiveDialog();
                    domStyle.set(this.broadnotifyReceiveDialog.closeButtonNode, 'display', 'none');
                    this.broadnotifyLoop();
                // }
                }
                // 初回ロード
                // this.processEvacRecommend(true);
                // this.processChronology();
                // this.processDamageReport(true);
                // this.processScheduledReport(true);
                // this.processTimeline();
                // this.processMessage(true);

                // 定期処理開始
                // this.timer = setInterval(lang.hitch(this, this.loop), this.REQUEST_INTERVAL * 60 * 1000);
                // 定期処理開始
                // this.timelineTimer = setInterval(lang.hitch(this, this.timelineLoop),
                //     this.TIMELINE_REQUEST_INTERVAL * 60 * 1000);
            }
        },

        /**
         * 定期的に実施するもの
         * @param isFirstTime : ログイン時・ページ更新時のみtrue, 他はfalse
         */
        loop: function () {
            // this.processEvacRecommend(false);
            this.processChronology();
            //this.processDamageReport(false);
            //this.processScheduledReport(false);
            this.processMessage(false);
        },

        /**
         * 緊急通知 定期処理
         */
        broadnotifyLoop: function() {
            this.processNotifyReport();
            // 関数processBbsReportを60000ミリ秒間隔で呼び出す
            this.timer = setInterval(lang.hitch(this, function() {
                this.processNotifyReport(); }), 1 * this.BROADNOTIFY_INTERVAL * 1000);
        },
        /**
         * 定期的に実施するもの
         * タイムラインのみ周期が異なるので別
         */
        timelineLoop: function () {
            this.processTimeline();
        },

        /**
         * お知らせ掲示板の情報を取得し、トーストを表示
         * @param isFirstTime : ログイン時・ページ更新時のみtrue, 他はfalse
         */
        processMessage: function (isFirstTime) {

            // ログインユーザの組織コードを取得する
            var orgCd = UserInfo.getOrganization().deptCd;

            //災害IDが切り替えられていたら、切り替え後のIDを利用
            var disasterId = this._disasterId ? this._disasterId : DisasterInfo.getDisasterId();

            if (!UserInfo.hasAuthz('F07001')) {
                return;
            }
            var levelList = ['undefined', 'low', 'middle', 'high', 'urgent'];

            // お知らせ情報取得のリクエストは2回に分けて実行する
            // 1: まず、currentuserの所属する本部が発表元または掲載先であるお知らせ情報を取得する
            Requester.get('/api/bbses?disasterId=' + disasterId + '&senderOrganizationCd=' + orgCd)
                .then(lang.hitch(this, function (data1) {
                    console.log('所属本部が発表元・掲載先であるお知らせ情報の一覧（' + this.orgCd + '）：' +
                        JSON.stringify(data1));
                    var resut1 = data1.items;

                    // 2: 次に、管理部が緊急情報として発表したお知らせ情報を取得する
                    Requester.get('/api/bbses?disasterId=' + disasterId + '&emergencyNewsFlg=1')
                        .then(lang.hitch(this, function (data2) {
                            console.log('管理部が緊急情報として発表したお知らせ情報の一覧（' + this.orgCd + '）：' +
                                JSON.stringify(data2));
                            var result2 = data2.items;

                            var result3 = resut1.concat(result2);

                            // currentuserが管理部に所属する場合、result1とresult2に重複が含まれる場合があるため重複をなくす
                            var wholeData = result3.filter((element, index, self) =>
                                self.findIndex(e =>
                                    e.messageId === element.messageId
                                )=== index
                            );

                            var messageTimeFrom = new Date();
                            var messageList = [];
                            var newMessageDays = 3 * 24 * 60 * 60 * 1000; // 3日 * 24時間 * 60分 * 60秒 * 1000ミリ秒
                            var messageLevel = 0;
                            array.forEach(wholeData, function (message) {
                                // ログイン時は、3日間のメッセージを集計して、件数を通知する。
                                //（金曜に登録->月曜に開封を想定し３日とする）
                                // 前回のログインからにしないのは、IDの使い回しにより、通されるべき人に通知されないことを避けるため、不採用とした。
                                if (isFirstTime && (messageTimeFrom.getTime() - newMessageDays) <
                                    new Date(message.updTimestamp.replace(/-/g, '/')).getTime()) {
                                    // メッセージレベルは、新着メッセージで最も上位の通知に寄せる。
                                    messageLevel = Math.max(messageLevel, parseInt(message.messageType, 10) - 1);
                                    messageList.push(message);
                                } else if (!isFirstTime &&
                                    ((messageTimeFrom.getTime() - this.REQUEST_INTERVAL * 15 * 60 * 1000) <
                                        new Date(message.updTimestamp.replace(/-/g, '/')).getTime())) {
                                    // ログイン時でなければ、直近1分間の間に新規追加or更新された情報を取得する
                                    // 複数件存在した場合、最も上位の通知に寄せる
                                    messageLevel = Math.max(messageLevel, parseInt(message.messageType, 10) - 1);
                                    messageList.push(message);
                                }
                            }, this);
                            if (messageList.length > 0) {
                                this.notify({
                                    title: '災害対応共有サイト',
                                    message: 'お知らせが「' + messageList.length + '」件あります。',
                                    level: levelList[messageLevel],
                                    page: 'disasterInfoShareDetail',
                                    deptId: orgCd,
                                    timeout: this.SHOW_DURATION * 1000
                                });
                            }
                    }), function (error2) {
                        if (error2.response.status === 404) {
                            console.error('管理部が緊急情報として発表したお知らせ情報が存在しません。', error2);
                        } else {
                            console.error('管理部が緊急情報として発表したお知らせ情報取得でエラー発生', error2);
                        }
                    });
            }), function (error1) {
                if (error1.response.status === 404) {
                    console.error('所属組織が発表元・掲載先であるお知らせ情報が存在しません。', error1);
                } else {
                    console.error('所属組織が発表元・掲載先であるお知らせ情報取得でエラー発生', error1);
                }
            });
        },

        /**
         * 避難情報発令判断支援情報を取得し、トーストを表示
         * @param isFirstTime : ログイン時・ページ更新時のみtrue, 他はfalse
         */
        processEvacRecommend: function (isFirstTime) {
            //災害IDが切り替えられていたら、切り替え後のIDを利用
            var disasterId = this._disasterId ? this._disasterId : DisasterInfo.getDisasterId();

            if (!UserInfo.hasAuthz('F05005')) {
                return;
            }

            Requester.get('/data/evacorder/recommend/' + disasterId + '/evacRecommend.json')
                .then(lang.hitch(this, function (data) {
                    console.debug('避難情報発令判断支援一覧（' + this._municipalityCd + '）：' +
                        JSON.stringify(data));
                    var wholeData = data.items;
                    var userMunics = UserInfo.getMunicipalityCds();
                    var evacRecommendTimeFrom = new Date();

                    var items = [];
                    array.forEach(wholeData, function (recommend) {
                        // ログイン時は、発令判断基準超過レコードが、ユーザの管理対象市町村だったら表示する
                        if (isFirstTime && userMunics.indexOf(recommend.municipalityCd) !== -1) {
                            items.push(recommend);
                        } else if (!isFirstTime && userMunics.indexOf(recommend.municipalityCd) !== -1 &&
                            ((evacRecommendTimeFrom.getTime() - this.REQUEST_INTERVAL * 60 * 1000) <
                                recommend.updTimestamp)) {
                            // ログイン時でなければ、発令判断基準超過レコードが、ユーザの管理対象市町村であるかつ
                            // 直近1分間の間に新規追加or更新された情報を取得する
                            items.push(recommend);
                        }
                    }, this);

                    if (items.length !== 0) {
                        var evacRecommendSummary = this.summarizeEvacRecommend(items);

                        var message = evacRecommendSummary.munucipalities + 'で、' +
                            evacRecommendSummary.maxEvacOrderType + '（' +
                            evacRecommendSummary.issueReasonTypes + '）の発令基準を超過しました。';

                        this.notify({
                            title: evacRecommendSummary.maxEvacOrderType + '基準超過',
                            message: message,
                            level: this.maxEvacRecommendLevel(evacRecommendSummary.maxEvacOrderTypeCd),
                            page: 'evacrecommend',
                            timeout: this.SHOW_DURATION * 1000
                        });
                    }
                }), function (error) {
                    if (error.response.status === 404) {
                        console.error('避難レコメンドが存在しません。', error);
                    } else {
                        console.error('避難レコメンドの情報取得でエラー発生', error);
                    }

                });
        },

        /**
         * クロノロジの最新を取得して通知
         */
        processChronology: function () {
            //災害IDが切り替えられていたら、切り替え後のIDを利用
            var disasterId = this._disasterId ? this._disasterId : DisasterInfo.getDisasterId();
            //リクエストインターバル分遡った時刻を取得する
            var now = new Date();
            var registerTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL - 5).getTime();
            var registerTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes()).getTime() - 1;

            console.log(new Date(now.getFullYear(), now.getMonth(), (now.getDate() - 1),
                now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL));
            var url = '/api/notification?disasterId=' + disasterId +
                '&resultLimitFlg=0' +
                '&registerTimestampFrom=' + registerTimestampFrom +
                '&registerTimestampTo=' + registerTimestampTo;
            url += '&userMunicipalityCd=' + UserInfo.getMunicipalityCd();
            url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();
            url += '&municipalityCd=' + UserInfo.getMunicipalityCd();
            url += '&organizationCd=' + UserInfo.getLowestOrganizationCd();
            url += '&deptCd=' + UserInfo.getDeptCd();

            // 例えば現在時刻が18:58:10で、REQUEST_INTERVALが1minだった場合、18:57:00~18:57:59.999までの間を検索期間とする
            Requester.get(url).then(lang.hitch(this, function (data) {
                if (data.total > this.THRESHOLD_GATHER) {
                    // 件数が多かったら1つにまとめる
                    // var message = '新着クロノロジが' + data.total + '件あります';
                    var message = '新着時系列情報が' + data.total + '件あります';
                    message += '（' + locale.format(now) + '現在）';
                    this.notify({
                        // title: 'クロノロジ',
                        title: '時系列情報',
                        message: message,
                        level: this.maxLevel(data.items),
                        timeout: this.SHOW_DURATION * 1000
                    });
                } else {
                    // まとめないときは1つずつ通知する
                    array.forEach(data.items, lang.hitch(this, function (item) {

                        // 内容が長すぎる場合は、一定の長さで内容を区切る。
                        var message = null;

                        var content = item.content ? item.content.replace('<br>', '') : '(詳細未報告)';
                        if (content.length > this.MAX_CONTENT_LENGTH) {
                            message = content.substring(0, this.MAX_CONTENT_LENGTH) + '…';
                        } else {
                            message = content;
                        }

                        message += '（' + locale.format(new Date(item.registerTimestamp)) + '）';

                        this.notify({
                            // title: 'クロノロジ(' + this.getChronologyType(item.chronologyType) + ')',
                            title: '時系列情報(' + this.getChronologyType(item.chronologyType) + ')',
                            message: message,
                            level: this.getLevel(item.urgencyType),
                            url: item.sourceUrl,
                            timeout: this.SHOW_DURATION * 1000
                        });
                    }));
                }

            }), function (error) {
                console.error('クロノロジ取得API呼び出し失敗', error);
            });
        },

        /**
         * 未対応の被害情報を取得して通知
         */
        processDamageReport: function (isFirstTime) {
            // ユーザの所属組織を取得
            var organizationCd = this.organizationCd.unitCd ? this.organizationCd.unitCd :
                this.organizationCd.sectCd ? this.organizationCd.sectCd :
                    this.organizationCd.deptCd;

            // 取得条件: 災害IDが一致、ユーザの所属組織が対応課に指定されている、対応状況が「未確認」または「依頼済」
            var url = '/api/damageReports?disasterId=' + DisasterInfo.getDisasterId() +
                //'&hldOrganization=' + UserInfo.getOrganizationCd().substr(1) + '&hldStatus=0,1&activeFlg=1';
                '&hldOrganization=' + organizationCd + '&hldStatus=0,1&activeFlg=1&reportStatus=';

            var now = new Date();
            var timeInfo = '';
            if (!isFirstTime) {
                // ログイン時以外は、直近REQUEST_INTERVAL分の間に更新されたものに限定して表示
                var registerTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                    now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL);
                var registerTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                    now.getHours(), now.getMinutes());

                // 例えば現在時刻が18:58:10で、REQUEST_INTERVALが1minだった場合、18:57:00~18:57:59.999までの間を検索期間とする
                url += '&reportUpdDateTimeFrom=' + registerTimestampFrom.getTime() + '&reportUpdDateTimeTo=' +
                    (registerTimestampTo.getTime() - 1);
                timeInfo = locale.format(registerTimestampFrom) +
                    '〜' + locale.format(registerTimestampTo);
            } else {
                // ログイン時は、上記取得条件を満たす全ての被害を表示
                timeInfo = '（' + locale.format(now) + '現在）';
            }


            Requester.get(url).then(lang.hitch(this, function (data) {
                if (data.total === 0) {
                    return;
                }
                var message = '';
                if (!isFirstTime) {
                    message = timeInfo + 'の間に対応課に指定された被害が' + data.total + '件あります';
                } else {
                    message = '対応課に指定された被害が' + data.total + '件あります';
                    message += timeInfo;
                }

                this.notify({
                    title: '未対応被害情報',
                    message: message,
                    level: this.maxLevel4Damage(data.items),
                    page: 'report',
                    timeout: this.SHOW_DURATION * 1000
                });

            }), function (error) {
                console.error('被害情報取得API呼び出し失敗', error);
            });
        },

        /**
         * 未報告の定時報告を取得して通知
         */
        processScheduledReport: function (isFirstTime) {
            // 関係機関には表示しない
            if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN) {
                return false;
            }
            var now = new Date();
            var aggrTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes());

            // 共通の取得条件: 災害IDが一致、集計時刻が現在時刻よりも前、依頼されているが未報告（＝報告をする必要がある）
            // TODO: 依頼された時点でも表示の必要あり？
            var url = '/api/scheduledReports?disasterId=' + DisasterInfo.getDisasterId() +
                '&requestingReportFlg=1&aggrDateTimeTo=' + aggrTimestampTo.getTime();

            // ユーザごとの取得条件
            if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                //市町ユーザなら、報告種別と市町コードで絞り込み（市町内の全ユーザに表示される）
                url += '&reportType=01&municipalityCd=' + UserInfo.getMunicipalityCd();
            } else if (UserInfo.getUserType() === USER_TYPE.REGION || UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                //振興局・県ユーザなら、報告種別で絞り込み（タワー内の全ユーザに表示される）
                var towerCd = UserInfo.getTowerCd();
                var towerToReportTypeMap =
                {
                    '02': '02',
                    '03': '03',
                    '04': '04',
                    '05': '05',
                    '06': '06',
                    '07': '07',
                    '08': '08',
                    '09': '09'
                };
                if (towerToReportTypeMap[towerCd]) {
                    var reportType = towerToReportTypeMap[towerCd];
                    url += '&reportType=' + reportType;
                } else {
                    // 02-09のタワーに属していない場合、通知を発行しない
                    return false;
                }
            }

            var timeInfo = '';
            if (!isFirstTime) {
                // ログイン時以外は、直近REQUEST_INTERVAL分以内に「集計時刻」を超過した依頼に限定する
                var aggrTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                    now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL);
                // 例えば現在時刻が18:58:10で、REQUEST_INTERVALが1minだった場合、18:57:00~18:57:59.999までの間を検索期間とする
                url += '&aggrDateTimeFrom=' + aggrTimestampFrom.getTime();
                timeInfo = locale.format(aggrTimestampFrom) +
                    '〜' + locale.format(aggrTimestampTo);
            } else {
                // ログイン時は、上記取得条件を満たす全ての被害を表示
                timeInfo = '（' + locale.format(now) + '現在）';
            }


            Requester.get(url).then(lang.hitch(this, function (data) {
                if (data.total === 0) {
                    return;
                }
                var message = '';
                if (!isFirstTime) {
                    message = timeInfo + 'の間に集計時刻を超過した定時報告が' + data.total + '件あります';
                } else {
                    message = '集計時刻を超過した定時報告が' + data.total + '件あります';
                    message += timeInfo;
                }

                this.notify({
                    title: '未完了の定時報告',
                    message: message,
                    level: this.getLevel('0'),
                    page: 'report/sche',
                    timeout: this.SHOW_DURATION * 1000
                });

            }), function (error) {
                console.error('定時報告取得API呼び出し失敗', error);
            });
        },

        /**
         * タイムラインの最新を取得して通知
         */
        processTimeline: function () {
            var now = new Date();

            // 実施時刻を超えているが、完了していない行動計画のうち、アラートフラグがオンであり、対象外フラグが立っていないもののみを検索する
            var url = '/api/timeline?disasterId=' + DisasterInfo.getDisasterId() +
                //'&startDateForSeach=' + (now.getTime()) +
                '&endDateForSeach=' + (now.getTime()) +
                '&alertSettingFlg=2&activeOnly=true';

            Requester.get(url).then(lang.hitch(this, function (data) {
                // 件数が多かったら1つにまとめる
                if (data.items.length > this.TIMELINE_GATHER) {
                    var message = '未実施のタイムラインが' + data.items.length + '件あります';
                    message += '（' + locale.format(now) + '現在）';
                    this.notify({
                        title: 'タイムライン',
                        message: message,
                        level: 3,
                        page: 'timeline',
                        timeout: this.TIMELINE_SHOW_DURATION * 1000
                    });
                }

                // まとめないときは1つずつ通知する
                array.forEach(data.items, lang.hitch(this, function (item) {

                    // 内容が長すぎる場合は、一定の長さで内容を区切る。
                    var message = '';
                    if (item.content.length > this.MAX_CONTENT_LENGTH) {
                        message += item.content.substring(0, this.MAX_CONTENT_LENGTH) + '…';
                    } else {
                        message += item.content;
                    }
                    message += '（予定時刻：' + locale.format(new Date(item.timestamp)) + '）';

                    this.notify({
                        title: 'タイムライン',
                        message: message,
                        level: this.getLevel(3),
                        page: 'timeline',
                        timeout: this.TIMELINE_SHOW_DURATION * 1000
                    });
                }));

            }), function (error) {
                console.error('タイムライン取得API呼び出し失敗', error);
            });
        },

        /**
         * 緊急通知を受信して通知
         */
         processNotifyReport: function() {
            console.debug('緊急通知受信処理を開始します。');
            //リクエストインターバル分遡った時刻を取得する
            var self = this;
            var now = new Date();
            var loginTimestamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                                                    now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL).getTime();
            var userId = UserInfo.getId();
            var dialog = self.broadnotifyReceiveDialog;
            //var page = dialog.getChildren()[0];
            var innerDialog = self.innerBroadnotifyReceiveDialog;
            //var innerDialog = dialog.getChildren()[0];
            var url = '/api/broadnotify/received/?userId=' + userId +
                '&loginTimestamp=' + loginTimestamp;
            if(innerDialog) {
                Requester.get(url).then(lang.hitch(this, function(data) {
                    if(data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            innerDialog.initDialog(data[i]);
                            dialog.show();
                            switch (data[i].notifyType){
                                case '01':
                                    innerDialog.audio(this.audioData[0]);
                                    break;
                                case '02':
                                    innerDialog.audio(this.audioData[1]);
                                    break;
                                case '03':
                                    innerDialog.audio(this.audioData[2]);
                                    break;
                                default:
                                    break;
                            }
                            clearInterval(self.timer);
                        }
                    }
    
                }), lang.hitch(function(error) {
                    console.error('緊急通知取得API呼び出し失敗', error);
                }));
            }
        },

        /**
         * 受信確認ダイアログを初期化する。
         */
        initReceiveDialog: function() {
            var self = this;
            var dialog = this.broadnotifyReceiveDialog;
            // if(this.dialog === null) {
            //     this.own(
            //         this.dialog = new BroadnotifyReceiveDialog());
            // }
            // var receiveDialog = this.dialog;
            var page = dialog.getChildren()[0];

            page.on('update', lang.hitch(this, function(evt) {
                var form = evt.value;
                var url = '/api/broadnotify/confim/' + form.broadnotifyDetailId;
                Requester.put(url).then(lang.hitch(this, function() {
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // loop処理開始
                        self.broadnotifyLoop();
                }), function(error) {
                    console.error('緊急通知取得API呼び出し失敗', error);
                });

            }));
        },

        maxLevel4Damage: function (items) {
            var maxLevel = 0;
            array.forEach(items, function (item) {
                if (item.urgencyType === '9') {
                    return;
                }
                if (maxLevel < item.urgencyType) {
                    maxLevel = item.urgencyType;
                }
            });

            return this.getLevel4Damage(maxLevel);
        },

        /**
         * 複数の通知のうち, 緊急度が最大のものを求める
         */
        maxLevel: function (items) {
            var maxLevel = 0;
            array.forEach(items, function (item) {
                if (item.urgencyType === '9') {
                    return;
                }
                if (maxLevel < item.urgencyType) {
                    maxLevel = item.urgencyType;
                }
            });

            return this.getLevel(maxLevel);
        },

        /**
         * widgetが居なくなる時に呼ばれる
         * 定期処理を止める
         */
        destroy: function () {
            this.inherited(arguments);

            // setInterval止める
            clearInterval(this.timer);
            // clearInterval(this.timelineTimer);
        },

        getLevel: function (urgencyType) {
            var levelMap = {
                '0': 'undefined',
                '1': 'low',
                '2': 'middle',
                '3': 'high'
            };

            return levelMap[urgencyType];
        },

        getLevel4Damage: function (urgencyType) {
            var levelMap = {
                '0': 'low',
                '1': 'middle',
                '2': 'high'
            };

            return levelMap[urgencyType];
        },

        /**
         * 複数の避難情報発令判断支援情報から、トースト表示用に必要な情報を抜き出す
         */
        summarizeEvacRecommend: function (items) {
            var maxEvacOrderTypeCd = 1;
            var issueReasonTypes = [];
            var issueReasonTypesName = [];
            var munucipalities = []; //maxEvacOrderTypeCdが設定されているevacRecommendDTOのmunicipality
            array.forEach(items, function (evacRecommend) {
                // 最も重度の高い避難区分を選ぶ 03(避難指示) > 02(避難勧告) > 01(避難準備)
                var currentEvacOrdetTypeInt = parseInt(evacRecommend.evacOrderType, 10);
                if (currentEvacOrdetTypeInt > maxEvacOrderTypeCd) {
                    maxEvacOrderTypeCd = currentEvacOrdetTypeInt;
                    // 最大の避難区分が更新された場合は、「最大の避難区分が出されている市町村リスト」 を更新
                    munucipalities = [];
                    munucipalities.push(evacRecommend.municipalityName);
                    issueReasonTypes = [];
                    issueReasonTypes.push(evacRecommend.issueReasonType);
                } else if (currentEvacOrdetTypeInt === maxEvacOrderTypeCd) {
                    if (munucipalities.indexOf(evacRecommend.municipalityName) === -1) {
                        munucipalities.push(evacRecommend.municipalityName);
                    }
                    if (issueReasonTypes.indexOf(evacRecommend.issueReasonType) === -1) {
                        issueReasonTypes.push(evacRecommend.issueReasonType);
                    }
                }
            }, this);
            if (munucipalities.length === 1) {
                munucipalities = munucipalities[0];
            } else {
                munucipalities = munucipalities[0] + 'など';
            }
            array.forEach(issueReasonTypes, function (issueReasonType) {
                issueReasonTypesName.push(this.getIssueReasonTypeName(issueReasonType));
            }, this);
            var summary = {};
            summary.maxEvacOrderTypeCd = maxEvacOrderTypeCd;
            summary.maxEvacOrderType = this.getEvacOrderType(maxEvacOrderTypeCd);
            summary.issueReasonTypes = issueReasonTypesName.join('・');
            summary.munucipalities = munucipalities;
            return summary;
        },

        getIssueReasonTypeName: function (issueReasonType) {
            var issueReasonTypeMap = {
                '01': '土砂',
                '02': '洪水',
                '03': '地震',
                '04': '津波',
                '05': '高潮',
                '06': '火災',
                '07': '暴風',
                // '08': '火山',
                '09': '国民保護'
            };
            return issueReasonTypeMap[issueReasonType];
        },
        getEvacOrderType: function (maxEvacOrderTypeCd) {
            var evacOrderTypeList = ['', '避難準備', '避難勧告', '避難指示'];
            return evacOrderTypeList[maxEvacOrderTypeCd];
        },

        maxEvacRecommendLevel: function (maxEvacOrderTypeCd) {
            // 発令判断支援パネルの色は、他と少しずれている
            var levelList = ['', 'middle', 'high', 'urgent'];
            return levelList[maxEvacOrderTypeCd];
        },

        getChronologyType: function (type) {
            var typeMap = {
                '00': 'その他',
                '01': '防災気象情報',
                '02': '国民保護情報',
                '03': '観測情報',
                '22': 'タイムライン',
                '23': '作図情報',
                '24': '避難情報',
                '25': '配備体制',
                '26': '避難所情報',
                '27': '被害情報',
                '28': '要請・措置情報',
                '29': '災害名管理',
                '30': '要請・措置',
                '31': '組織内情報',
                '32': '部隊活動情報',
                '33': '通行規制情報',
                '34': '大阪府連携',
                '41': '定時報告依頼',
                '42': '定時報告'
            };

            return typeMap[type];
        }
    });
});
