/**
 * ラウター用モジュール
 *
 * @module app/control/AdisTrainingRouter
 */
define(['module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/has',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'src/modules/index.js'
], function (module, declare, lang, has, Router, UserInfo, USER_TYPE) {
    /**
     * ラウター
     *
     * @class AdisRouter
     * @extends module:idis/control/Router~Router
     */
    return declare(module.id.replace(/\//g, '.'), Router, /** @lends app/control/AdisRouter */
        {
            // 初期化処理
            init: function () {
                // ユーザ情報に応じてURLのマッピングを登録
                return UserInfo.load().then(lang.hitch(this, function () {

                    var trainingFlg = UserInfo.isTrainingFlg();
                    console.debug('訓練モード : ' + trainingFlg);

                    // FIXME デフォルト画面の設定を見直す
                    if (UserInfo._userInfo.roleCd === 'R02002') {
                        this.register('app/evacorder/EvacOrderRegisterPage');
                    } else {
                        this.register('app/view/page/DisasterPortalPage');
                    }


                    if (has('dojo-debug-messages')) {
                        // 開発時用画面
                        this.register({
                            'sample': 'app/sample/view/SampleGridPage',
                            'sample/map': 'app/sample/view/SampleGridMapPage'
                        });
                    }

                    // 避難所支援メッセージ機能
                    // if (UserInfo.hasAuthz('F06003')) {
                    //     this.register({
                    //         'message': 'app/message/MessageListPage',
                    //         'message-register': 'app/message/MessageRegisterPage',
                    //         'message-detail': 'app/message/MessageDetailPage'
                    //     });
                    // }

                    // マスター管理機能
                    // if (UserInfo.hasAuthz('F09002')) {
                    //     this.register({
                    //         'master': 'app/master/MasterPage'
                    //     });
                    // }

                    this.register({
                        'login': 'app/login/view/LoginPage',
                        'login/training': 'app/login/view/TrainingLoginPage'
                    });

                    // F01001：監視ページ
                    if (UserInfo.hasAuthz('F01001')) {
                        this.register({
                            'monitoring': 'app/view/page/MonitoringPage'
                        });
                    }

                    // F02001：災害情報マップ
                    if (UserInfo.hasAuthz('F02001')) {
                        this.register({
                            'map': 'app/view/page/MapPage'
                        });
                    }

                    // F03001：気象情報一覧
                    if (UserInfo.hasAuthz('F03001')) {
                        this.register({
                            'weatherinfo': 'app/weatherinfo/view/WeatherInfoPage',
                            'weatherinfo/region': 'app/weatherinfo/view/WeatherInfoPageRegion',
                            'weatherinfo/timeline': 'app/weatherinfo/view/WeatherInfoTimelinePage',
                            'weatherinfo/detail': 'app/weatherinfo/view/WeatherInfoDetailPage',
                            'weatherinfo/history': 'app/weatherinfo/view/WeatherInfoHistoryPage'
                        });
                    }

                    // F03002：地震情報一覧
                    if (UserInfo.hasAuthz('F03002')) {
                        this.register({
                            'earthquake': 'app/earthquake/view/EarthquakePage'
                        });
                    }


                    // F03002：火山情報一覧
                    if (UserInfo.hasAuthz('F03002')) {
                        this.register({
                            'volcano': 'app/volcano/view/VolcanoPage',
                            'volcano/detail': 'app/volcano/VolcanoDetailPage',
                            'volcano/ash': 'app/volcano/AshDetailPage',
                            'volcano/ashBreaking': 'app/volcano/AshBreakingNewsDetailPage'
                        });
                    }


                    // F03002：津波情報一覧
                    if (UserInfo.hasAuthz('F03002')) {
                        this.register({
                            'tsunami': 'app/tsunami/view/TsunamiPage'
                        });
                    }


                    // F03001：災害概況帳票出力
                    if (UserInfo.hasAuthz('F03001')) {
                        this.register({
                            'disasteroverview': 'app/disasteroverview/DisasterOverviewPage'
                        });
                    }


                    // F03002：洪水予報情報一覧
                    if (UserInfo.hasAuthz('F03002')) {
                        this.register({
                            'floodforecast': 'app/floodforecast/view/FloodForecastPage'
                        });
                    }

                    // F03001：リンク集
                    if (UserInfo.hasAuthz('F03001')) {
                        this.register({
                            'weatherLinks': 'app/view/page/WeatherLinkCollectionPage'
                        });
                    }

                    // F04001：土砂災害危険度情報一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        this.register({
                            'sedimentriskobservation': 'app/sedimentrisk/SedimentRiskObservationPage'
                        });
                    }

                    // F0400x：観測情報概況
                    if (UserInfo.hasAuthz('F04001') || UserInfo.hasAuthz('F04003') || UserInfo.hasAuthz('F04004')) {
                        this.register({ 'observationmap': 'app/observationmap/ObservationMapPage' });
                    }

                    // F04002：雨量情報一覧
                    // if (UserInfo.hasAuthz('F04001')) {
                    //     this.register({
                    //         'observation/rainfall': 'app/rain/RainfallObservationPage',
                    //         'observation/rainfall/hourly': 'app/rain/RainfallObservationHourlyPage',
                    //         'observation/rainfall/detail': 'app/rain/RainfallObservationDetailPage',
                    //         'observation/rainfall/ranking': 'app/rain/RainfallObservationRankingPage'
                    //     });
                    // }

                    // F04001：河川水位情報一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        this.register({
                            'observation/river': 'app/river/RiverLevelObservationPage',
                            'observation/river/detail': 'app/river/RiverLevelObservationDetailPage'
                        });
                    }

                    // F04001：危機管理型水位計情報一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        this.register({
                            'observation/crisisManage': 'app/river/CrisisManageRiverLevelObsPage',
                            'observation/crisisManage/detail': 'app/river/CrisisManageRiverLevelObsDetailPage'
                        });
                    }

                    // F04001：潮位実況情報一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        this.register({
                            'observation/tide': 'app/tide/TideLevelObservationPage',
                            'observation/tide/detail': 'app/tide/TideLevelObservationDetailPage'
                        });
                    }

                    // F04001：市内気象情報一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        this.register({
                            'observation/local': 'app/localobservation/LocalObservationPage',
                            'observation/local/detail': 'app/localobservation/LocalObservationDetailPage'
                        });
                    }

                    // 流域平均雨量情報一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        this.register({
                            'observation/basin': 'app/riverBasin/RiverBasinRainfallPage',
                            'observation/basin/detail': 'app/riverBasin/RiverBasinRainfallDetailPage'
                        });
                    }

                    // ダム諸量情報一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        this.register({
                            'observation/dam': 'app/dam/DamPage',
                            'observation/dam/detail': 'app/dam/DamDetailPage'
                        });
                    }

                    // F04005：観測局一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        this.register({
                            'observationstations': 'app/observationstation/ObservationStationsPage'
                        });
                    }

                    // ライフライン
                    if (UserInfo.hasAuthz('F05016')) {
                        this.register({
                            'lifeline/admin': 'app/lifeline/LifelineAdminPage',
                            'lifeline': 'app/lifeline/LifelineListPage',
                            'lifeline/register': 'app/lifeline/LifelineRegisterPage',
                            'lifeline/detail': 'app/lifeline/LifelineDetailPage'
                        });
                    }

                    // F06006：市民への情報配信履歴
                    if (UserInfo.hasAuthz('F06006')) {
                        this.register({
                            'citizenInfoHistory': 'app/citizenInfoHistory/CitizenInfoHistoryAdminPage'
                        });
                    }

                    // F05001：情報配信
                    if (UserInfo.hasAuthz('F06001')) {
                        this.register({
                            'provideinformation': 'app/provide/ProvideInformationPage',
                            'provideinformation/temp': 'app/provide/ProvideTemplatePage'
                        });
                    }

                    // F05002：体制状況・報告
                    if (UserInfo.hasAuthz('F05002')) {
                        this.register({
                            'disasterprevention': 'app/disasterprevention/view/DisasterPreventionListPage',
                            'disasterprevention/pref': 'app/disasterprevention/view/DisasterPreventionPrefListPage',
                            'disasterprevention/status': 'app/disasterprevention/view/DisasterPreventionStatusPage',
                            'disasterprevention/status/pref':
                                'app/disasterprevention/view/DisasterPreventionPrefStatusPage'
                        });
                    }


                    // F05003：職員参集管理
                    if (UserInfo.hasAuthz('F05003')) {
                        this.register({
                            'employgathering': 'app/employgathering/EmployGatheringStatusListPage',
                            'employgathering/pref': 'app/employgathering/EmployGatheringStatusPrefListPage'
                        });
                    }

                    // F05004：避難状況・報告
                    if (UserInfo.hasAuthz('F05004')) {
                        this.register({
                            'evacorder/admin': 'app/evacorder/EvacOrderAdminPage',
                            'evacorder': 'app/evacorder/EvacOrderPage',
                            'evacorder/detail': 'app/evacorder/EvacOrderDetailPage',
                            'evacorder/register': 'app/evacorder/EvacOrderRegisterPage',
                            'evacorder/registerFromRecommend': 'app/evacorder/EvacOrderRegisterFromRecommendPage',
                            'evacorder/registerFromDetailPage': 'app/evacorder/EvacOrderRegisterFromDetailPage',
                            'evacorder/district': 'app/evacorder/EvacOrderDistrictPage'
                        });
                    }

                    // F05005：避難情報発令基準超過
                    if (UserInfo.hasAuthz('F05005')) {
                        this.register({
                            'evacrecommend': 'app/evacrecommend/EvacRecommendPage',
                            'evacstdlimitover': 'app/evacstdlimitover/EvacStdLimitOverPage'
                        });
                    }

                    // F05006：避難所開設情報
                    if (UserInfo.hasAuthz('F05006')) {
                        this.register({
                            'shelter/admin': 'app/shelter/ShelterAdminPage',
                            'shelter/register': 'app/shelter/ShelterRegisterPage',
                            'shelter/detail': 'app/shelter/ShelterDetailPage',
                            'shelter/operating': 'app/shelter/ShelterBulkOperatingPage',
                            'shelter': 'app/shelter/ShelterListPage',
                            'shelter/status': 'app/shelter/status/ShelterStatusListPage'
                        });
                    }

                    // F05007：被害状況・報告
                    if (UserInfo.hasAuthz('F05007')) {
                        this.register({
                            'report': 'app/damage/DamageReportPage',
                            'report/view': 'app/damage/DamageReportViewPage',
                            'report/register': 'app/damage/damageReportRegister/DamageReportRegisterPage',
                            'overReport/register': 'app/damage/damageReportRegister/DamageReportRegisterPage',
                            'report/detail': 'app/damage/damageReportDetail/DamageReportDetailPage',
                            'report/detail/view': 'app/damage/damageReportDetail/DamageReportDetailViewPage',
                            'report/admin': 'app/damage/damageReportAdmin/DamageReportAdminPage',
                            'report/admin/view': 'app/damage/damageReportAdmin/DamageReportAdminViewPage',
                            'report/aggregate': 'app/damage/damageReportAggregate/DamageReportAggregatePage',
                            'report/aggregate/view': 'app/damage/damageReportAggregate/DamageReportAggregateViewPage'
                        });
                    }
                    // F05007：定時集計
                    if (UserInfo.hasAuthz('F05007')) {
                        this.register({
                            'scheduledReport': 'app/scheduledReport/ScheduledReportListPage',
                            'scheduledReport/view': 'app/scheduledReport/ScheduledReportListViewPage',
                            'scheduledReport/regist': 'app/scheduledReport/ScheduledReportPage',
                            'scheduledReport/detail': 'app/scheduledReport/ScheduledReportPage',
                            'scheduledReport/detail/view': 'app/scheduledReport/ScheduledReportViewPage',
                            'scheduledReport/munic': 'app/scheduledReport/ScheduledReportMunicListPage',
                            'scheduledReport/munic/view': 'app/scheduledReport/ScheduledReportMunicListViewPage',
                            'scheduledReport/munic/detail': 'app/scheduledReport/ScheduledReportMunicDetailPage',
                            'scheduledReport/munic/detail/view': 'app/scheduledReport/ScheduledReportMunicDetailViewPage',
                            'scheduledReport/damage': 'app/scheduledReport/ScheduledReportDamageListPage',
                            'scheduledReport/damage/view': 'app/scheduledReport/ScheduledReportDamageListViewPage',
                            'scheduledReport/damage/detail': 'app/scheduledReport/ScheduledReportDamageDetailPage',
                            'scheduledReport/damage/detail/view': 'app/scheduledReport/ScheduledReportDamageDetailViewPage'
                        });
                    }

                    // F06005：備蓄物資・救援物資管理
                    // if (UserInfo.hasAuthz('F06005')) {
                    //     this.register({
                    //         'materialmanagement': 'app/materialmanagement/MaterialManagementAdmin',
                    //         'materialmanagement/page': 'app/materialmanagement/MaterialManagementAdminPage',
                    //         'materialmanagement/register':
                    //                  'app/materialmanagement/MaterialManagementRegisterPage'
                    //     });
                    // }

                    // F06006：要請・措置状況
                    // if (UserInfo.hasAuthz('F06006')) {
                    //     this.register({
                    //         'requestmeasure': 'app/requestmeasure/RequestMeasureAdminPage',
                    //         'requestmeasure/register': 'app/requestmeasure/RequestMeasureRegisterPage'
                    //     });
                    // }

                    // F05001：時系列情報管理
                    if (UserInfo.hasAuthz('F05001')) {
                        this.register({
                            'chronology/admin': 'app/chronology/ChronologyAdminPage',
                            'chronology': 'app/chronology/ChronologyListPage',
                            'chronology/register': 'app/chronology/ChronologyRegisterPage',
                            'chronology/detail': 'app/chronology/ChronologyDetailPage'
                        });
                    }

                    // F05011：通行規制情報管理
                    if (UserInfo.hasAuthz('F05011')) {
                        this.register({
                            'traffic': 'app/traffic/TrafficRegulationPage',
                            'traffic/register': 'app/traffic/TrafficRegulationRegisterPage',
                            'traffic/detail': 'app/traffic/TrafficRegulationDetailPage',
                            'traffic/import': 'app/traffic/import/TrafficImportInfoListPage'
                        });
                    }

                    // F05012：Spectee情報管理
                    if (UserInfo.hasAuthz('F05012')) {
                        this.register({
                            'spectee': 'app/spectee/SpecteeAdminPage',
                            'spectee/detail': 'app/spectee/SpecteeAdminDetailPage'
                        });
                    }

                    // F09001：災害名管理
                    if (UserInfo.hasAuthz('F09001') && UserInfo.getUserType() !== USER_TYPE.MUNICIPALITY) {
                        this.register({
                            'disaster': 'app/disaster/view/DisasterAdminPage'
                        });
                    }

                    // F09002：フォルダ管理
                    if (UserInfo.hasAuthz('F09002')) {
                        this.register({
                            'folder': 'app/folder/view/FolderPage'
                        });
                    }

                    // F09003：タイムライン管理
                    if (UserInfo.hasAuthz('F09003')) {
                        this.register({
                            'timeline': 'app/timeline/TimelinePage'
                        });
                    }

                    // FIXME: functionCdを振る
                    // F07005：住所情報地図表示機能
                    // if(UserInfo.hasAuthz('F07005')) {
                    //     this.register({
                    //         'geoplot': 'app/geoplot/GeoplotPage'
                    //     });
                    // }
                    // 保守用
                    this.register({
                        'geoplot': 'app/geoplot/GeoplotPage'
                    });

                    // F09002：地図電子ファイル管理管理
                    if (UserInfo.hasAuthz('F09002')) {
                        this.register({
                            'geofile': 'app/geofile/GeoFilePage'
                        });
                    }

                    // F08001：招集状況管理
                    if (UserInfo.hasAuthz('F08001')) {
                        this.register({
                            'convocation/mail': 'app/convocation/view/mail/ConvocationMailAdminPage',
                            'convocation/statusList': 'app/convocation/view/mail/ConvoStatusListPage',
                            'convocation/statusDetail': 'app/convocation/view/mail/ConvoStatusDetailPage',
                            'convocation/history': 'app/convocation/view/history/ConvocationMailHistoryPage'
                        });
                    }

                    // F08002：職員招集設定管理
                    if (UserInfo.hasAuthz('F08002')) {
                        this.register({
                            'safetyLogin': 'app/safety/SafetyLoginPage',
                            'safety': 'app/safety/SafetyTopPage',
                            'safety/mailTemp': 'app/safety/mail/SafetyMailTempPage',
                            'safety/statusOverview': 'app/safety/mail/SafetyMailOverviewPage',
                            'safety/statusList': 'app/safety/mail/SafetyMailListPage',
                            'safety/statusDetail': 'app/safety/mail/SafetyMailDetailPage',
                            'safety/statusRegister': 'app/safety/mail/SafetyStatusRegisterPage',
                            'convocation': 'app/convocation/view/ConvocationTopPage',
                            'convocation/mailTemp': 'app/convocation/view/mail/ConvoMailTempPage',
                            'convocation/groupRegister': 'app/convocation/view/group/ConvoGroupRegisterPage',
                            'convocation/groupDetail': 'app/convocation/view/group/ConvoGroupDetailPage',
                            'convocation/autoMailRegister':
                                'app/convocation/view/condition/ConvoAutoMailRegisterPage',
                            'convocation/autoMailDetail': 'app/convocation/view/condition/ConvoAutoMailDetailPage',

                            'convocation/employee': 'app/convocation/view/employee/ConvocationEmployeeAdminPage',
                            'convocation/group': 'app/convocation/view/group/ConvocationGroupAdminPage',
                            'convocation/automail': 'app/convocation/view/condition/ConvocationAutoCondAdminPage',
                            'convocation/user': 'app/convocation/view/user/UserAdminPage',

                            'convocation/weather': 'app/convocation/view/weather/WeatherMailAdminPage',
                            'convocation/weather/register': 'app/convocation/view/weather/WeatherMailRegisterPage',
                            'convocation/weather/detail': 'app/convocation/view/weather/WeatherMailDetailPage',

                            'convocation/agency': 'app/convocation/view/agency/AgencyAdminPage'

                        });
                    }

                    // F10001：避難所施設管理
                    if (UserInfo.hasAuthz('F10001')) {
                        this.register({
                            'facility': 'app/facility/FacilityAdminPage',
                            'facility/register': 'app/facility/FacilityRegisterPage',
                            'facility/detail': 'app/facility/FacilityDetailPage'
                        });
                    }

                    // F10005：施設管理
                    if (UserInfo.hasAuthz('F10005')) {
                        this.register({
                            'facilityMaster': 'app/facilityMaster/FacilityMasterListPage',
                            'facilityMaster/register': 'app/facilityMaster/FacilityMasterRegisterPage',
                            'facilityMaster/detail': 'app/facilityMaster/FacilityMasterDetailPage'
                        });
                    }
                    // F09002：ユーザ管理
                    if (UserInfo.hasAuthz('F10003')) {
                        this.register({
                            'user': 'app/user/UserAdminPage'
                        });
                    }

                    // F09002：組織管理情報
                    // TODO: あとで変更
                    if (UserInfo.hasAuthz('F09002')) {
                        console.log('組織管理情報');
                        this.register({
                            'organization': 'app/organization/OrganizationAdminPage'
                            // 'organization': 'app/organization'
                        });
                    }

                    // F09003：物品管理
                    // if (UserInfo.hasAuthz('F09003')) {
                    //     this.register({
                    //         'item': 'app/item/ItemAdminPage'
                    //     });
                    // }

                    // F05010：部隊活動情報管理
                    // TODO:あとで変更
                    if (UserInfo.hasAuthz('F05010')) {
                        this.register({
                            'rescue': 'app/rescue/RescuePage',
                            'rescue/register': 'app/rescue/RescueRegisterPage',
                            'rescue/detail': 'app/rescue/RescueDetailPage'
                        });
                    }

                    // F06002：防災ポータル情報配信管理
                    if (UserInfo.hasAuthz('F06002')) {
                        this.register({
                            'prefsitesending': 'app/prefsitesending/PrefSiteSendingPage',
                            'prefsitesending/template': 'app/prefsitesending/PrefSiteSendingTemplatePage'
                        });
                    }

                    // F06001：配信ポータル
                    // TODO:コードを修正する
                    if (UserInfo.hasAuthz('F06001')) {
                        this.register({
                            'sending': 'app/sending/SendingPage',
                            'sending/register': 'app/sending/SendingRegisterPage',
                            'sending/template': 'app/sending/SendingTemplatePage',
                            'sending/detail': 'app/sending/SendingDetailPage',

                            'sendingHistory': 'app/sendingHistory/SendingHistoryPage',
                            'sendingHistory/detail': 'app/sendingHistory/SendingHistoryDetailPage',
                            'sendingHistory/register': 'app/sendingHistory/SendingHistoryRegisterPage'
                        });
                    }

                    // 掲示板
                    this.register({
                        'bbs': 'app/bbs/BbsAdminPage'
                    });

                    // 災害対応共有サイト
                    this.register({
                        'disasterInfoShareList': 'app/disasterInfoShare/DisasterInfoShareListPage',
                        'disasterInfoShareDetail': 'app/disasterInfoShare/DisasterInfoShareDetailPage'
                    });

                    // 災害シミュレーション
                    if (UserInfo.hasAuthz('F12001')) {
                        this.register({
                            'simulation': 'app/simulation/SimulationScenarioListPage',
                            'simulation/event': 'app/simulation/SimulationEventListPage',
                            'simulation/history': 'app/simulation/SimulationEventHistoryListPage'
                        });
                    }

                    // 緊急通知
                    // FUNC_CDが振られたら修正
                    // ログインユーザのユーザIDの末尾に"honbu"が記述されていないならメニュー表示しない
                    // var regexp = /^(?!.*-c).*(?=honbu).*$/;
                    // 「honbu」を含むユーザのみ権限を与える
                    var regexp = /^(?=.*honbu).*$/;
                    // if (UserInfo.hasAuthz('F12001')) {
                    if (regexp.test(UserInfo.getId()) || UserInfo.getId() === 'osaka-adm') {
                        this.register({
                            'broadnotify': 'app/broadnotify/BroadnotifyPage',
                            'broadnotify/detail': 'app/broadnotify/BroadnotifyDetailPage',
                            'broadnotify/template': 'app/broadnotify/BroadnotifyTemplateGridPage',
                            'broadnotify/terminal': 'app/broadnotify/TerminalListPage',
                            'broadnotify/group': 'app/broadnotify/BroadnotifyGroupListPage',
                            'broadnotify/group/register': 'app/broadnotify/BroadnotifyGroupRegisterPage',
                            'broadnotify/group/detail': 'app/broadnotify/BroadnotifyGroupDetailPage'
                        });
                    }
                    // }

                    // F13001：チャットグループ管理
                    if (UserInfo.hasAuthz('F13001')) {
                        this.register({
                            'chatGroup': 'app/chat/ChatGroupPage',
                            'chatUser': 'app/chat/ChatUserPage',
                            'chatUser/register': 'app/chat/ChatUserRegisterPage',
                            'chatUser/bulk': 'app/chat/ChatUserBulkPage'
                        });
                    }
                    // F13002：チャット履歴
                    if (UserInfo.hasAuthz('F13002')) {
                        this.register({
                            'chatHistory': 'app/chat/ChatHistoryPage'
                        });
                    }
                    // F13003：指示共有サイト画面
                    if (UserInfo.hasAuthz('F13003')) {
                        this.register({
                            'chatShare/group': 'app/chat/ChatShareGroupPage',
                            'chatShare': 'app/chat/ChatSharePage',
                            'chatShare/status': 'app/chat/ChatShareStatusPage'
                        });
                    }

                    // FIXME 利用しないURLを削除すること
                    this.register({
                        'photo': 'app/view/page/PhotoUploadPage',
                        'traffic': 'app/traffic/view/TrafficRegulationPage',
                        'traffic/register': 'app/traffic/view/TrafficRegulationRegisterPage',
                        'traffic/detail': 'app/traffic/view/TrafficRegulationDetailPage',
                        // 'shelter': 'app/view/page/ShelterFilePage',
                        'notice': 'app/notice/view/NoticePage',
                        'lalert': 'app/lalert/LalertPage',
                        'lalert/detail': 'app/lalert/LalertDetailPage'
                    });

                    // 帳票ディスプレイ
                    if (UserInfo.hasAuthz('F08002')) {
                        this.register({
                            'formDisplay': 'app/formDisplay/formDisplayAdminPage',
                            'moveDisplay': 'app/formDisplay/moveDisplayPage',
                            'displayLogin': 'app/formDisplay/displayLoginPage'
                        });
                    }
                                        
                    // F06010：水道局情報閲覧
                    if (UserInfo.hasAuthz('F06010')) {
                        this.register({
                            'waterworks': 'app/waterworks/WaterworksPage',
                            'waterworks/incident': 'app/waterworks/IncidentPage'
                        });
                    }

                    // F06011：消防局情報閲覧
                    if (UserInfo.hasAuthz('F06011')) {
                        this.register({
                            'fdmaDisasterSituation': 'app/fdmaDisasterSituation/FdmaDisasterSituationPage',
                        });
                    }

                    // 防災リンク集
                    this.register({
                        'dpLinks': 'app/view/page/DpLinkCollectionPage'
                    });

                    // マニュアルダウンロード用画面
                    this.register({
                        'manual': 'app/download/ManualDownloadPage'
                    });

                    // よくある質問用画面
                    this.register({
                        'questions': 'app/view/page/QuestionsPage'
                    });

                    // 災害ポータル画面
                    this.register({
                        'disasterPortal': 'app/view/page/DisasterPortalPage'
                    });

                    // パスワード変更画面
                    this.register({
                        'updatePassword': 'app/password/UpdatePasswordPage'
                    });

                    // ユーザ情報が取れない場合は常にログイン・ページを表示
                }), lang.hitch(this, function () {
                    // 訓練用ログイン
                    this.register('app/login/view/TrainingLoginPage');

                }));
            }
        });
});
