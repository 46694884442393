/**
 * メニュー用モジュール。
 * @module app/view/Menu
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/query',
    'dojo/has',
    'dojo/text!./templates/Menu.html',
    'dojo/topic',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/consts/ACL',
    'app/model/DisasterInfo',
    'app/config'
    // 以下、変数で受けないモジュール
], function (module, declare, lang, domClass, query, has, template, topic, _WidgetBase, _TemplatedMixin,
    Locator, Router, UserInfo, UserType, ACL, DisasterInfo, config) {
    /**
     * リンクの情報を管理するオブジェクト。
     * @typedef LinkInfo
     * @property {string} title リンクのtitle属性（カーソルを合わせたときの表示）
     * @property {string} label リンク文字列
     * @property {string} page ページ識別子、指定時はhrefよりも優先
     * @property {string} href リンク先
     * @property {LinkInfo[]} links リンクの情報の配列
     */

    /**
     * メニュー用オブジェクト
     * @class Menu
     */
    return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _TemplatedMixin],
        /** @lends app/view/Menu~Menu# */ {
            // テンプレート文字列
            templateString: template,

            MENU_TYPE_FLAT: 'フラット型メニュー',
            MENU_TYPE_HIERARCHY: '階層型メニュー',

            menuLabelOpen: '▼ メニュー',
            menuLabelClose: '▶︎ メニュー',

            /**
             * リンク情報の配列
             * @type {module: app/view/Menu~LinkInfo[]}
             */
            _links: null,

            href: null,

            // ユーザーの市町村コード
            _municipalityCd: null,

            // LGWAN側アクセスどうか
            _lgwanFlg: false,

            //
            disasterChgDialog: 'app/view/form/DisasterChangerDialog::initMenu',
            disasterUpd: 'app/disaster/view/DisasterAdminPage::initMenu',
            disasterChg: 'app/view/form/DisasterChanger::initMenu',


            // DOM要素準備後に呼ばれる
            buildRendering: function () {
                this.inherited(arguments);

                this.initMenu();

                //            var message = UserInfo.getId() + ' は';
                //            if(UserInfo.getRoleCd() === ACL.ADMIN_USER) {
                //                message = message + '管理ユーザです。';
                //            } else if(UserInfo.getRoleCd() === ACL.HQ_USER) {
                //                message = message + '本部ユーザです。';
                //            } else if(UserInfo.getRoleCd() === ACL.DEPT_USER) {
                //                message = message + '部局ユーザです。';
                //            } else if(UserInfo.getRoleCd() === ACL.CITY_H_USER) {
                //                message = message + '政令指定市ユーザです。';
                //            } else if(UserInfo.getRoleCd() === ACL.CITY_USER) {
                //                message = message + '市/行政区ユーザです。';
                //            } else if(UserInfo.getRoleCd() === ACL.FIRE_USER) {
                //                message = message + '消防ユーザです。';
                //            } else if(UserInfo.getRoleCd() === ACL.OTHER_USER) {
                //                message = message + 'その他ユーザです。';
                //            } else {
                //                message = message + '未定義ユーザです。 役割コード:' + UserInfo.getRoleCd();
                //            }
                //            console.debug(message);
                //            console.debug('ACL ADMIN : ' + UserInfo.getAcl().ADMIN);
                //            console.debug('ACL CHIEF ADMIN: ' + UserInfo.getAcl().CHIEF_ADMIN);
                //
                //            // ユーザーの市町村コードをセット
                //            // 非ログイン時にgetMunicipalityCds()[0]でエラーが生じるのを回避
                //            if(UserInfo.getMunicipalityCd()){
                //                this._municipalityCd = UserInfo.getMunicipalityCd();
                //            }
                //
                //            // LGWAN側アクセスであるか
                //            // var runningMode = UserInfo.getRunningMode();
                //            // if (runningMode === 'LGWAN') {
                //            //     this._lgwanFlg = true;
                //            // }
                //
                //            // リンク一覧を生成
                //            this._links = [];
                //
                //            if(this.getMenuType() === this.MENU_TYPE_FLAT) {
                //                // メニュータイプ：フラット型
                //                this._links = this.createMenuTypeFlatLinks();
                //            } else if(this.getMenuType() === this.MENU_TYPE_HIERARCHY) {
                //                // メニュータイプ：階層型
                //                this._links = this.createMenuTypeHierarchyLinks();
                //            } else {
                //                // メニュータイプ：階層型
                //                this._links = this.createMenuTypeHierarchyLinks();
                //                console.warn('メニュータイプが未定義です。 メニュータイプ:' + this.getMenuType());
                //            }
                //
                //            this._links.push();
                //            this.updateLinks();
                //            this.own(Locator.on('change', lang.hitch(this, 'updateLinks')));

                // 災害名変更がPubされた際にツリーの情報を更新する
                topic.subscribe(this.disasterChgDialog, lang.hitch(this, function () {
                    this.initMenu();
                }));

                // 災害名管理更新がPubされた際にツリーの情報を更新する
                topic.subscribe(this.disasterUpd, lang.hitch(this, function () {
                    this.initMenu();
                }));

                // 災害名表示がPubされた際にツリーの情報を更新する
                topic.subscribe(this.disasterChg, lang.hitch(this, function () {
                    this.initMenu();
                }));
            },

            postCreate: function () {
                this.inherited(arguments);
                // リンクがクリックされたら反応する
                this.on('a:click', lang.hitch(this, function (e) {
                    this.href = e.target.href;
                    var menuLink = false; // ヘッダーに表示されているメニューリンク判定フラグ

                    if (e.target.parentElement.parentElement.id === 'linkList') { // リンクタグの親の親のIDがlinkListの場合
                        menuLink = true; // メニューリンクと判定する

                        // 過去に開いたメニューのエレメントを探す
                        var oldEl = e.target.parentElement.parentElement.querySelector('li.isOpen');

                        if (oldEl) { // 開いたメニューがある場合
                            oldEl.classList.remove('isOpen'); // 閉じる
                        }
                        if (e.target.parentElement !== oldEl) { // 過去に開いたリンクが、今回開いたリンクと異なる場合、
                            e.target.parentElement.classList.add('isOpen'); // メニューを開く
                        }

                    }
                    if (this.href.indexOf(Locator.getHref()) === -1 &&
                        Locator.getHref().indexOf(this.href) === -1) {
                        this.isMenuOpen = false;
                    }
                    if (!Locator.isCurrentPath(this.href)) {
                        // ブラウザーの遷移処理をキャンセル
                        e.preventDefault();
                        if (!menuLink) { // メニューリンクではない場合、遷移先にJump
                            // ページへ遷移
                            Locator.pushState(Locator.getQueryFrom(this.href), true);
                        }
                    }
                    // ページ指定になっていない場合はそのまま通す
                }));

                this.own(Locator.on('change', lang.hitch(this, function () {

                    console.debug('this.href = ' + this.href);
                    console.debug('Locator.getHref() = ' + Locator.getHref());

                    if (!(this.href === Locator.getHref() ||
                        this.href === Locator.getHref() + '#')) {
                        this.isMenuOpen = false;
                    }
                })));



            },

            /**
             * リンク一覧のDOMノードを更新する。
             */
            updateLinks: function () {

                // 新たなリンク一覧を文字列として構築
                var html = [];
                for (var i = 0; i < this._links.length; i++) {
                    var link = this._links[i];
                    // html.push('<li class=""> <a href="');
                    if (link.links) {
                        html.push('<li class=""> <a href="');
                        html.push('#"');
                        if (link.className) {
                            html.push(' class="' + link.className + '"');
                        }
                        html.push('>');
                        html.push(link.label);
                        html.push('</a><ul>');
                        for (var j = 0; j < link.links.length; j++) {
                            var subLink = link.links[j];
                            if (subLink.label === '物資要請') {
                                // 物資調達・輸送調整等支援システムをログイン済状態で別画面に開く
                                html.push(this.onBusshiLinkClick());
                            } else {
                                html.push('<li><a ');
                                if (subLink.external) {
                                    html.push('href="');
                                    // 外部リンクページの場合
                                    html.push(subLink.url);
                                    // 別タブで開く
                                    html.push('" target="_blank" class="u-external is-inverted"');
                                } else {
                                    html.push('href="javascript:void(0);" onClick="');

                                    html.push('var win = window.location = \'');
                                    html.push(subLink.page ?
                                        ('?' + Router.PAGE_KEY + '=' + subLink.page) : (subLink.href || '#'));

                                    // 閲覧用メニューの場合、閲覧モードフラグをセット
                                    if (link.label.indexOf('閲覧') !== -1 &&
                                        subLink.label.indexOf('河川水位情報') === -1 &&
                                        subLink.label.indexOf('避難情報発令判断支援') === -1) {
                                        console.log('here');
                                        html.push('&viewMode=1');
                                    }
                                    html.push('\';');

                                    html.push('win.reload();');
                                }
                                html.push('" title="');
                                html.push(subLink.title);
                                html.push('">');
                                html.push(subLink.label);
                                html.push('</a></li>');
                            }
                        }
                        html.push('</ul></li>');
                    } else if (link.label==='FAQ' || link.label==='訓練シミュレーション'){
                        // メニューにサブリンクがない場合（直接リンク）
                        html.push('<li class=""> ');
                        html.push('<a href="javascript:void(0);" onClick="');
                        html.push('var win = window.location = \'');
                        html.push(link.page ?
                            ('?' + Router.PAGE_KEY + '=' + link.page) : (link.href || '#'));
                        html.push('\';');
                        html.push('win.reload();');
                        html.push('" title="');
                        html.push(link.title);
                        html.push('">');
                        html.push(link.label);
                        html.push('</a></li>');
                        html.push('</ul></li>');
                    } else {
                        html.push('<li class=""> <a href="');
                        if (link.extlink) {
                            html.push(link.extlink);
                        } else {
                            // 市町ユーザーの場合、メニューから一覧画面へ直接遷移する
                            if (!link.municipalityCd) {
                                html.push(link.page ? ('?' + Router.PAGE_KEY + '=' + link.page) : (link.href || '#'));
                            } else {
                                html.push(link.page ? '?municipalityCd=' + link.municipalityCd +
                                    '&' + Router.PAGE_KEY + '=' + link.page : (link.href || '#'));
                            }
                        }
                        if (link.target) {
                            html.push('" target="');
                            html.push(link.target);
                        }
                        html.push('" title="');
                        html.push(link.title);
                        html.push('</a></li>');
                    }
                }

                // リンク一覧の中身を置き換える
                this.linkList.innerHTML = html.join('');
            },

            onBusshiLinkClick: function () {
                var html = [];
                // window.openで \ADISClient\build\busshi.html を呼び出す
                // このHTMLは、ADISClient\src\templates\busshi.mustache より生成される
                var busshiLoginPage = './busshi.html';
                // var trainingFlg = 1; // 0: 実災害モード、1: 訓練モード
                var trainingFlg = UserInfo.getLoginMode() === '本番' ? '0' : '1';
                var govUserId = UserInfo.getGovUserId();
                var govPassword = UserInfo.getGovPassword();

                // パラメータの文字列連結(訓練フラグ・ユーザーID・パスワードをカンマ区切りで連結して送信)
                var url = busshiLoginPage + '?' + trainingFlg + ',' + govUserId + ',' + govPassword;

                html.push('<li><a href="javascript:void(0);" onClick="');

                // 入力チェック
                html.push('var govUserId = \'' + govUserId + '\';');
                html.push('var govPassword = \'' + govPassword + '\';');

                if (govUserId === null || govPassword === null) {
                    // 戻り先URLの設定
                    if (trainingFlg === '0') {
                        // 実災害モードの場合
                        url = 'https://busshi.bousai-system.go.jp';
                    } else if (trainingFlg === '1') {
                        // 訓練モードの場合
                        url = 'https://busshi.bousai-system.go.jp/training/index.html';
                    }
                }

                // ポップアップブロックを抑止するためブランク画面にURLをセット
                html.push('var win = window.open(\'\',\'_Busshi\');');
                html.push('win.location = \'' + url + '\';');

                // 訓練モードの場合、1回目はログイン不可。2回目でログイン可能となるので再度window.openする
                html.push('if (\'' + trainingFlg + '\'=== \'1\'){');
                html.push('    setTimeout(function(){window.open(\'\',\'_Busshi\').close();},800);');
                html.push('    setTimeout(function(){window.open(\'' + url + '\',\'_Busshi\');},800);');
                html.push('}"');

                html.push('title="物資調達システムを表示します。">物資要請</a></li>');
                return html.join('');
            },

            isMenuOpen: false,

            onMenuLinkClick: function () {
                console.debug('メニューリンクがクリックされました');

                if (!this.isMenuOpen) {
                    console.debug('メニューリンクをオープンします。');
                    this.isMenuOpen = true;
                } else {
                    console.debug('メニューリンクをクローズします。');
                    this.isMenuOpen = false;
                }
            },

            getMenuType: function () {
                var menuType = '';

                if (UserInfo.getRoleCd() === ACL.ADMIN_USER ||
                    UserInfo.getRoleCd() === ACL.HQ_USER) {
                    menuType = this.MENU_TYPE_HIERARCHY;
                } else if (UserInfo.getRoleCd() === ACL.DEPT_USER ||
                    UserInfo.getRoleCd() === ACL.CITY_H_USER ||
                    UserInfo.getRoleCd() === ACL.CITY_USER ||
                    UserInfo.getRoleCd() === ACL.FIRE_USER ||
                    UserInfo.getRoleCd() === ACL.OTHER_USER) {
                    menuType = this.MENU_TYPE_FLAT;
                } else {
                    // 未定義の役割だった場合は階層型メニュータイプを設定
                    menuType = this.MENU_TYPE_HIERARCHY;
                    console.warn('役割コードが未定義です。 役割コード:' + UserInfo.getRoleCd());
                }

                console.debug('メニュータイプ：' + menuType);

                return menuType;
            },

            // サブメニューリンク
            // mainMenuLinksLabel: 'メニュー',
            mainMenuLinksLabel: '災害対応',
            mainMenuLinksViewLabel: '閲覧',
            // evacSubLinksLabel: '避難情報',
            disSubLinksLabel: '外部連携等',
            infoShareLinkLabel: '情報共有',
            wtrSubLinksLabel: '閲覧（防災気象情報）',
            obsSubLinksLabel: '閲覧（観測情報）',
            // supSubLinksLabel: '防災業務支援',
            masterSubLinksLabel: 'メンテナンス',
            convocationSubLinksLabel: '職員参集・緊急通知',
            formDisplaySubLinksLabel: '帳票ディスプレイ',
            otherSubLinksLabel: 'その他',
            // lifelineSubLinksLabel: 'ライフライン',

            // メニューリンク
            monitoringLink: {
                page: 'monitoring', label: '地図',
                title: '地図を表示します。'
            },
            weatherinfoLink: {
                page: 'weatherinfo', label: '気象情報',
                title: '気象情報一覧画面を表示します。'
            },
            earthquakeLink: {
                page: 'earthquake', label: '地震情報',
                title: '地震情報一覧画面を表示します。'
            },
            tsunamiLink: {
                page: 'tsunami', label: '津波情報',
                title: '津波情報一覧画面を表示します。'
            },
            floodforecastLink: {
                page: 'floodforecast', label: '指定河川洪水予報',
                title: '指定河川洪水予報一覧を表示します。'
            },
            //        linkcollectionLink: {page: 'weatherLinks', label: '気象リンク集',
            //            title: '気象情報関連のリンク集画面を表示します。'},
            // sedimentriskobservationLink: {
            //     page: 'sedimentriskobservation', label: '土砂災害危険度情報',
            //     title: '土砂災害危険度情報一覧画面を表示します。'
            // },
            //        observationmapLink: {page: 'observationmap', label: '観測情報概況',
            //            title: '各観測情報の概況情報を表示します。'},
            // rainfallobservationLink: {
            //     page: 'observation/rainfall', label: '雨量情報',
            //     title: '雨量情報一覧画面を表示します。'
            // },
            riverlevelobservationLink: {
                page: 'observation/river', label: '河川水位情報',
                title: '水位実況情報一覧画面を表示します。'
            },
            riverlevelobservationViewLink: {
                page: 'observation/river', label: '河川水位情報',
                title: '水位実況情報一覧画面を表示します。'
            },
            // basinRainfallIndexLink: {
            //     page: 'basinRainfallIndex', label: '流域雨量指数', title: '流域雨量指数を表示します。',
            //     external: true, url: 'https://www.jma.go.jp/bosai/floodindex/#area_type=class20s&area_code=2710000'
            // },
            // crisisManageRiverlevelObsLink: {
            //     page: 'observation/crisisManage', label: '危機管理型水位計情報',
            //     title: '危機管理型水位計情報一覧画面を表示します。'
            // },
            //        localobservationLink: {page: 'observation/local', label: '市内気象情報',
            //            title: '市内気象情報一覧画面を表示します。'},
            // basinAveRainfallObservationLink: {
            //     page: 'observation/basin', label: '流域平均雨量情報',
            //     title: '流域平均雨量実況情報一覧画面を表示します。'
            // },
            tidelevelobservationLink: {
                page: 'tide', label: '潮位情報', title: '大阪府河川防災情報を表示します。',
                external: true, url: 'http://www.osaka-kasen-portal.net/suibou/'
            },
            // damobservationLink: {
            //     page: 'observation/dam', label: 'ダム情報',
            //     title: 'ダム諸量実況情報一覧画面を表示します。'
            // },
            // riverCameraobservationLink: {
            //     page: 'riverCameraobservation', label: '河川カメラ実況情報一覧',
            //     title: '河川カメラ実況情報一覧画面を表示します。'
            // },
            //        observationstationsLink: {page: 'observationstations', label: '観測局管理',
            //            title: '観測局一覧画面を表示します。'},
            //        provideinformationLink: {page: 'sending', label: '情報配信',
            //            title: '情報配信画面を表示します。'},
            // lifelineLink: {
            //     page: 'lifeline/admin', label: 'ライフライン',
            //     title: 'ライフライン情報の管理を行います。'
            // },
            disasterpreventionLink: {
                page: 'disasterprevention', label: '本部体制・動員体制',
                title: '本部体制・動員体制の登録・更新を行います。'
            },
            disasterpreventionViewLink: {
                page: 'disasterprevention', label: '本部体制・動員体制',
                title: '本部体制・動員体制の参照を行います。'
            },
            // disasterpreventionStatusLink: {
            //     page: 'disasterprevention/status', label: '配備体制',
            //     title: '体制状況・報告の登録・更新を行います。'
            // },
            evacorderAdminLink: {
                page: 'evacorder/admin', label: '避難情報',
                title: '避難情報の登録・更新を行います。'
            },
            evacorderAdminViewLink: {
                page: 'evacorder/admin', label: '避難情報',
                title: '避難情報の参照を行います。'
            },
            evacRecommendLink: {
                page: 'evacrecommend', label: '避難情報発令判断支援',
                title: '避難情報発令基準超過画面を表示します。'
            },
            evacRecommendViewLink: {
                page: 'evacrecommend', label: '避難情報発令判断支援',
                title: '避難情報発令基準超過画面を表示します。'
            },
            shelterAdminLink: {
                page: 'shelter/admin', label: '避難所情報',
                title: '避難所情報の管理を行います。'
            },
            shelterAdminViewLink: {
                page: 'shelter/admin', label: '避難所情報',
                title: '避難所情報の参照を行います。'
            },
            shelterListLink: {
                page: 'shelter', label: '避難所情報',
                title: '避難所情報の管理を行います。'
            },
            shelterListViewLink: {
                page: 'shelter', label: '避難所情報',
                title: '避難所情報の参照を行います。'
            },
            reportAdminLink: {
                page: 'report/admin', label: '被害情報',
                title: '被害情報の登録・更新を行います。'
            },
            reportAdminViewLink: {
                page: 'report/admin/view', label: '被害情報',
                title: '被害情報の参照を行います。'
            },
            reportLink: {
                page: 'report', label: '被害情報',
                title: '被害情報の登録・更新を行います。'
            },
            reportViewLink: {
                page: 'report/view', label: '被害情報',
                title: '被害情報の参照を行います。'
            },

            // timeLineLink: {page: 'timeline', label: 'タイムライン',
            //     title: 'タイムライン情報の登録・更新を行います。'},
            // overReportLink: {page: 'overReport/register', label: '簡易報告',
            //     title: '簡易報告の登録を行います。'},
            // requestmeasureLink: {
            //     page: 'requestmeasure', label: '要請・措置状況',
            //     title: '要請・措置状況の登録・更新を行います。'
            // },
            chronologyLink: {
                page: 'chronology', label: '府連携・市本部内班間連携',
                title: '府連携・市本部内班間連携の登録・更新を行います。'
            },
            // trafficRegulationLink: {
            //     page: 'traffic', label: '道路通行規制',
            //     title: '通行規制情報の登録・更新を行います。'
            // },
            // trafficImportLink: {page: 'traffic/import', label: '道路交通情報取込',
            //     title: '通行規制情報の取り込みを行います。'},
            // rescueLink: {page: 'rescue', label: '部隊活動情報',
            //     title: '部隊活動情報の登録・更新を行います。'},
            sendingHistoryLink: {
                page: 'sendingHistory', label: '情報配信',
                title: '情報配信画面を表示します。'
            },
            specteeLink: {
                page: 'spectee', label: 'SNS情報管理',
                title: 'SNS情報の管理を行います。'
            },
            citizenInfoHistoryLink: {
                page: 'citizenInfoHistory', label: '市民への情報配信履歴',
                title: '市民への情報配信履歴の管理を行います。'
            },
            fdmaDisasterSituationLink: {
                page: 'fdmaDisasterSituation', label: '消防局情報閲覧',
                title: '消防局情報閲覧の参照を行います。'
            },
            waterworksLink: {
                page: 'waterworks', label: '水道局情報閲覧',
                title: '水道局情報閲覧の参照を行います。'
            },
            disasterLink: {
                page: 'disaster', label: '災害名管理',
                title: '災害名の登録・更新を行います。'
            },
            disasterViewLink: {
                page: 'disaster', label: '災害名管理',
                title: '災害名の参照を行います。'
            },
            // disasterDialogLink: {
            //     page: 'disasteroverview', label: '災害概況帳票出力',
            //     title: '災害概況帳票の出力を行います。'
            // },
            folderLink: {
                page: 'folder', label: 'フォルダ管理',
                title: '表示情報ツリーの移動・名前変更・新規作成・削除を行います。'
            },
            // convocationLink: {
            //     page: 'convocation', label: '招集状況管理',
            //     title: '招集状況情報の管理を行います。'
            // },
            convocationMailLink: {
                page: 'convocation/mail', label: '職員参集管理',
                title: 'メール配信、および、配信メールの管理を行います。'
            },
            // convocationEmployeeLink: {
            //     page: 'convocation/employee', label: '職員情報',
            //     title: '職員の管理を行います。'
            // },
            convocationGroupLink: {
                page: 'convocation/group', label: '配信グループ管理',
                title: '配信グループの管理を行います。'
            },
            convocationAutoMailLink: {
                page: 'convocation/automail', label: '配信テンプレート管理',
                title: '配信テンプレートの管理を行います。'
            },
            convocationHistoryLink: {
                page: 'convocation/history', label: '（旧）職員参集管理',
                title: '旧職員参集システムの履歴管理を行います。'
            },
            // convocationUserLink: {page: 'convocation/user', label: '職員招集管理者管理',
            //     title: '職員招集管理者情報を登録・更新・削除します。'},
            // convocationWeatherMailLink: {page: 'convocation/weather', label: '気象メール自動配信条件',
            //     title: '気象メール自動配信条件の管理を行います。'},
            // convocationAgencyLink: {page: 'convocation/agency', label: '伝達先機関一覧',
            //     title: '伝達先機関の管理を行います。'},
            facilityLink: {
                page: 'facility', label: '避難所情報マスタ管理',
                title: '本システムの避難所情報を登録・更新・削除します。'
            },
            facilityMasterLink: {
                page: 'facilityMaster', label: '施設情報マスタ管理',
                title: '本システムの施設情報を登録・更新・削除します。'
            },
            userLink: {
                page: 'user', label: '利用者管理',
                title: '本システムの利用者情報を登録・更新・削除します。'
            },
            chatGroupLink: {
                page: 'chatGroup', label: '指示共有グループ管理',
                title: '指示共有グループ情報を登録・更新・削除します。'
            },
            disasterInfoShareListLink: {
                page: 'disasterInfoShareList', label: '災害対応共有サイト',
                title: '災害対応情報を一覧表示・登録・更新・削除します。'
            },
            chatHistoryLink: {
                page: 'chatHistory', label: '指示共有機能履歴管理',
                title: '指示共有機能履歴一覧画面を表示します。'
            },
            chatShareLink: {
                page: 'chatShare/group', label: '指示共有サイト',
                title: '指示共有画面を表示します。'
            },
            // itemLink: {
            //     page: 'item', label: '物品管理',
            //     title: '本システムの物品情報を登録・更新・削除します。'
            // },
            // organizationLink: {
            //     page: 'organization', label: '組織管理',
            //     title: '組織管理情報を開きます。'
            // },
            // prefsitesendingLink: {page: 'prefsitesending', label: '防災ポータル情報配信',
            //     title: '防災ポータル情報配信を表示します。'},
            // supplyrequestLink: {
            //     label: '物資要請',
            //     title: '物資調達システムを表示します。',
            //     href: 'https://busshi.bousai-system.go.jp/training/index.html',
            //     target: '_blank'
            // },
            // dpLinkPageLink: {
            //     page: 'dpLinks', label: '防災リンク集',
            //     title: '防災情報関連のリンク集画面を表示します。'
            // },
            manualLink: {
                page: 'manual', label: 'ダウンロード',
                title: 'Androidのモバイルアプリがダウンロードできます。'
            },
            // bbsLink: {page: 'bbs', label: '掲示板',
            //     title: '職員向けお知らせ掲示板画面を表示します。'},
            simulationLink: {
                page: 'simulation', label: '訓練シミュレーション',
                title: '訓練シミュレーションを登録・更新・削除します。'
            },
            questionsLink: {
                page: 'questions', label: 'FAQ',
                title: '防災情報システムに関する、よくある質問を表示します。'
            },
            broadnotifyLink: {
                page: 'broadnotify', label: '緊急通知',
                title: '緊急通知を行います。'
            },
            terminalLink: {
                page: 'broadnotify/terminal', label: '緊急通知先ユーザ管理',
                title: '緊急通知先のユーザ管理を行います。'
            },
            broadnotifyGroupLink: {
                page: 'broadnotify/group', label: '緊急通知グループ管理',
                title: '緊急通知グループの管理を行います。'
            },
            formDisplayLink: {
                page: 'formDisplay', label: '帳票ディスプレイ表示設定管理',
                title: '帳票ディスプレイの設定管理を行います。'
            },
            // moveDisplayLink: {
            //     page: 'moveDisplay', label: '帳票ディスプレイメニュー',
            //     title: '帳票ディスプレイの画面表示を行います。'
            // },
            displayLoginLink: {
                page: 'displayLogin', label: '帳票ディスプレイログイン',
                title: '帳票ディスプレイのログイン画面を表示します。'
            },
            disasterPortalLink: {
                page: 'disasterPortal', label: '災害ポータル',
                title: '災害ポータル画面を表示します。'
            },
            /*        createMenuTypeFlatLinks : function() {
             
                        var links = [];
             
                        // F05001：クロノロジー管理
                        if(UserInfo.hasAuthz('F05001')) {
                            links.push(this.chronologyLink);
                        }
             
                        // F05007：被害情報
                        if(UserInfo.hasAuthz('F05007')) {
                            if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                                this.reportLink.municipalityCd =this._municipalityCd;
                                links.push(this.reportLink);
                            }else{
                                links.push(this.reportAdminLink);
                            }
                        }
             
                        // 避難・避難所情報
                        {
                            var evacSubLinks = [];
             
                            // F05004：被害情報
                            if(UserInfo.hasAuthz('F05004')) {
                                if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                                    this.evacorderAdminLink.municipalityCd = this._municipalityCd;
                                    this.evacorderAdminLink.page = 'evacorder';
                                }
                                evacSubLinks.push(this.evacorderAdminLink);
                            }
             
                            // F05004：避難情報発令判断支援
                            if(UserInfo.hasAuthz('F05005')) {
                                evacSubLinks.push(this.evacRecommendLink);
                            }
             
                            // F05006：避難所開設情報
                            if(UserInfo.hasAuthz('F05006')) {
                                if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                                    this.shelterListLink.municipalityCd =this._municipalityCd;
                                    evacSubLinks.push(this.shelterListLink);
                                }else{
                                    evacSubLinks.push(this.shelterAdminLink);
                                }
                            }
             
                            // F10001/F10002：施設管理
                            if(UserInfo.hasAuthz('F10001') || UserInfo.hasAuthz('F10002')) {
                                evacSubLinks.push(this.facilityLink);
                            }
             
                            if(evacSubLinks.length > 0) {
                                links.push({label: this.evacSubLinksLabel, links: evacSubLinks});
                            }
                        }
             
                        // 災害情報収集・伝達
                        {
                            var disSubLinks = [];
             
                            // F05002：体制状況・報告
                            if(UserInfo.hasAuthz('F05002')) {
                                // 行政区も他の体制を参考にするため、概況画面へ遷移できるよう修正
                                //if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                                //    this.disasterpreventionLink.page = 'disasterprevention/status';
                                // } else if (UserInfo.getUserType() === UserType.REGION){
                                //     this.disasterpreventionLink.page = 'disasterprevention/status/pref';
                                //}
                                links.push(this.disasterpreventionLink);
                            }
             
                            // F05011：通行規制情報管理
                            if(UserInfo.hasAuthz('F05011')) {
                                links.push(this.trafficRegulationLink);
                            }
             
                            // F05010：部隊活動情報管理
                            // if(UserInfo.hasAuthz('F05010')) {
                            //     links.push(this.rescueLink);
                            // }
             
                            // F05012：Spectee情報管理
                            if (!this._lgwanFlg) {
                                if(UserInfo.hasAuthz('F05012')) {
                                    links.push(this.specteeLink);
                                }
                            }
             
                            // F06001：情報配信
                            if(UserInfo.hasAuthz('F06001')) {
                                links.push(this.provideinformationLink);
                            }
             
                            // F06002：防災ポータル情報配信
                            if(UserInfo.hasAuthz('F06002')) {
                                links.push(this.prefsitesendingLink);
                            }
             
                            if(disSubLinks.length > 0) {
                                links.push({label: this.disSubLinksLabel, links: disSubLinks});
                            }
                        }
             
                        // 職員招集
                        {
                            var convocationSubLinks = [];
             
                            // F08001：招集状況管理(職員招集メール配信管理)
                            if(UserInfo.hasAuthz('F08001')) {
                                // 職員参集メール配信情報
                                convocationSubLinks.push(this.convocationMailLink);
                            }
             
                            // F08002：職員招集設定管理
                            if(UserInfo.hasAuthz('F08002')) {
                                // 職員情報(職員管理)
                                convocationSubLinks.push(this.convocationEmployeeLink);
                                // グループ情報(グループ管理)
                                convocationSubLinks.push(this.convocationGroupLink);
                                // 配信テンプレート設定(配信条件設定管理)
                                convocationSubLinks.push(this.convocationAutoMailLink);
                                // 職員招集管理者管理
                                // convocationSubLinks.push(this.convocationUserLink);
                            }
             
                            if(convocationSubLinks.length > 0) {
                                links.push({label: this.convocationSubLinksLabel, links: convocationSubLinks});
                            }
                        }
             
                        // 防災気象情報
                        {
                            var wtrSubLinks = [];
             
                            // F03001：気象情報一覧
                            if(UserInfo.hasAuthz('F03001')) {
                                wtrSubLinks.push(this.weatherinfoLink);
                            }
             
                            // F03002：地震情報一覧
                            if(UserInfo.hasAuthz('F03002')) {
                                wtrSubLinks.push(this.earthquakeLink);
                            }
             
                            // F03002：津波情報一覧
                            if(UserInfo.hasAuthz('F03002')) {
                                wtrSubLinks.push(this.tsunamiLink);
                            }
             
                            // F03002：洪水予報情報一覧
                            if(UserInfo.hasAuthz('F03002')) {
                                wtrSubLinks.push(this.floodforecastLink);
                            }
             
                            // F03001：リンク集
                            if(UserInfo.hasAuthz('F03001')) {
                                wtrSubLinks.push(this.linkcollectionLink);
                            }
             
                            if(wtrSubLinks.length > 0) {
                                links.push({label: this.wtrSubLinksLabel, links: wtrSubLinks});
                            }
                        }
             
                        // 観測情報等
                        {
                            var obsSubLinks = [];
             
                            // F04001：観測情報概況
                            if(UserInfo.hasAuthz('F04001')) {
                                obsSubLinks.push(this.observationmapLink);
                            }
             
                            // F04001：土砂災害危険度情報一覧
                            // if(UserInfo.hasAuthz('F04001')) {
                            //     obsSubLinks.push(this.sedimentriskobservationLink);
                            // }
             
                            // F04001：雨量情報一覧
                            if(UserInfo.hasAuthz('F04001')) {
                                obsSubLinks.push(this.rainfallobservationLink);
                            }
             
                            // F04001：河川水位情報一覧
                            if(UserInfo.hasAuthz('F04001')) {
                                obsSubLinks.push(this.riverlevelobservationLink);
                            }
             
                            // // F04001：流域平均雨量
                            // if(UserInfo.hasAuthz('F04001')) {
                            //     obsSubLinks.push(this.basinAveRainfallObservationLink);
                            // }
             
                            // F04001：潮位実況情報一覧
                            // if(UserInfo.hasAuthz('F04001')) {
                            //     obsSubLinks.push(this.tidelevelobservationLink);
                            // }
             
                            // F04001：風向・風速情報一覧
                            // if(UserInfo.hasAuthz('F04001')) {
                            //     obsSubLinks.push(this.windobservationLink);
                            // }
             
                            // F04001：ダム情報一覧
                            // if(UserInfo.hasAuthz('F04001')) {
                            //     obsSubLinks.push(this.damobservationLink);
                            // }
             
                            if(obsSubLinks.length > 0) {
                                links.push({label: this.obsSubLinksLabel, links: obsSubLinks});
                            }
                        }
             
                        // 防災業務支援 (管理)
                        {
                            var supSubLinks = [];
             
                            // F09001：災害名管理
                            if(UserInfo.hasAuthz('F09001')&&
                                // 訓練モードの場合、市町村ユーザーで権限があれば表示
                                (UserInfo.isTrainingFlg() ||
                                UserInfo.getUserType()!==UserType.MUNICIPALITY)) {
                                supSubLinks.push(this.disasterLink);
                            }
             
                            // F10003：利用者管理
                            if(UserInfo.hasAuthz('F10003')) {
                                supSubLinks.push(this.userLink);
                            }
             
                            // F10004：組織管理情報
                            if(UserInfo.hasAuthz('F10004')) {
                                supSubLinks.push(this.organizationLink);
                            }
             
                             // F09002：フォルダ管理
                            if(UserInfo.hasAuthz('F09002')) {
                                supSubLinks.push(this.folderLink);
                            }
             
                            // 防災リンク集
                            supSubLinks.push(this.dpLinkPageLink);
             
                            // マニュアルダウンロード
                            supSubLinks.push(this.manualLink);
             
                            // 掲示板画面
                            //supSubLinks.push(this.bbsLink);
             
                            // 災害シミュレーション（訓練モードの時のみ表示）
                           if(UserInfo.hasAuthz('F12001') && UserInfo.isTrainingFlg()) {
                               supSubLinks.push(this.simulationLink);
                           }
             
                            // よくある質問
                            supSubLinks.push(this.questionsLink);
             
                            // F09002：地図電子ファイル管理
                            // if(UserInfo.hasAuthz('F09002')) {
                            //     supSubLinks.push(this.geofileLink);
                            // }
             
                            // if(supSubLinks.length > 0) {
                            //     links.push({label: this.supSubLinksLabel, links: supSubLinks});
                            // }
                        }
             
                        return links;
                    },*/

            createMenuTypeHierarchyLinks: function () {

                var links = [];

                // 災害対応
                {
                    var mainMenuSubLinks = [];

                    // F09001：災害名管理
                    if (UserInfo.hasAuthz('F09001') &&
                        // 訓練モードの場合、市町村ユーザーで権限があれば表示
                        (UserInfo.isTrainingFlg() ||
                            UserInfo.getUserType() !== UserType.MUNICIPALITY)) {
                        mainMenuSubLinks.push(this.disasterLink);
                    }


                    // F05002：配備体制(体制状況・報告)
                    if (UserInfo.hasAuthz('F05002')) {
                        // 行政区も他の体制を参考にするため、概況画面へ遷移できるよう修正
                        //if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                        this.disasterpreventionLink.page = 'disasterprevention';
                        // } else if (UserInfo.getUserType() === UserType.REGION){
                        //     this.disasterpreventionLink.page = 'disasterprevention/status/pref';
                        //}
                        // if(UserInfo.getUserType() === UserType.MUNICIPALITY &&
                        //    UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                        //     this.disasterpreventionLink.municipalityCd =this._municipalityCd;
                        //     mainMenuSubLinks.push(this.disasterpreventionStatusLink);
                        // }else{
                        mainMenuSubLinks.push(this.disasterpreventionLink);
                        // }
                    }

                    // F05007：被害情報
                    if (UserInfo.hasAuthz('F05007')) {
                        // 市本部ユーザ以上
                        if (UserInfo.getApprovalType() === '1') {
                            mainMenuSubLinks.push(this.reportAdminLink);
                        }
                        else {
                            mainMenuSubLinks.push(this.reportLink);
                        }
                    }

                    // F05006：避難所開設状況
                    if (UserInfo.hasAuthz('F05006')) {
                        if ((UserInfo.getUserType() === UserType.MUNICIPALITY ||
                            UserInfo.getUserType() === UserType.OTHER_ORGAN) &&
                            UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                            this.shelterListLink.municipalityCd = this._municipalityCd;
                            mainMenuSubLinks.push(this.shelterListLink);
                        } else {
                            mainMenuSubLinks.push(this.shelterAdminLink);
                        }
                    }

                    // F05010：部隊活動情報管理
                    // if(UserInfo.hasAuthz('F05010')) {
                    //     mainMenuSubLinks.push(this.rescueLink);
                    // }

                    // F09003：タイムライン
                    //     if(UserInfo.hasAuthz('F09003')) {
                    //      mainMenuSubLinks.push(this.timeLineLink);
                    //  }

                    // F05004：避難情報
                    if (UserInfo.hasAuthz('F05004')) {
                        if (UserInfo.getUserType() === UserType.MUNICIPALITY &&
                            UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                            this.evacorderAdminLink.municipalityCd = this._municipalityCd;
                            this.evacorderAdminLink.page = 'evacorder';
                        }
                        mainMenuSubLinks.push(this.evacorderAdminLink);
                    }
                    // F05005：避難情報発令判断支援
                    // if (UserInfo.hasAuthz('F05005')) {
                    //    mainMenuSubLinks.push(this.evacRecommendLink);
                    // }

                    // F04001：河川水位情報
                    // if (UserInfo.hasAuthz('F04001')) {
                    //    mainMenuSubLinks.push(this.riverlevelobservationLink);
                    //}

                    // ダウンロードページ
                    mainMenuSubLinks.push(this.manualLink);

                    // 災害ポータルページ
                    //mainMenuSubLinks.push(this.disasterPortalLink);

                    if (mainMenuSubLinks.length > 0) {
                        links.push({ label: this.mainMenuLinksLabel, links: mainMenuSubLinks, className: 'menuClass' });
                    }
                }

                // 閲覧
                {
                    var mainMenuViewSubLinks = [];

                    // F09001：災害名管理
                    if (UserInfo.hasAuthz('F09001') &&
                        // 訓練モードの場合、市町村ユーザーで権限があれば表示
                        (UserInfo.isTrainingFlg() ||
                            UserInfo.getUserType() !== UserType.MUNICIPALITY)) {
                        mainMenuViewSubLinks.push(this.disasterViewLink);
                    }
                    // F05002：配備体制(体制状況・報告)
                    if (UserInfo.hasAuthz('F05002')) {
                        // 行政区も他の体制を参考にするため、概況画面へ遷移できるよう修正
                        this.disasterpreventionViewLink.page = 'disasterprevention';
                        mainMenuViewSubLinks.push(this.disasterpreventionViewLink);
                    }
                    // F05007：被害情報
                    if (UserInfo.hasAuthz('F05007')) {
                        // 市本部ユーザ以上
                        if (UserInfo.getApprovalType() === '1') {
                            mainMenuViewSubLinks.push(this.reportAdminViewLink);
                        }
                        else {
                            mainMenuViewSubLinks.push(this.reportViewLink);
                        }
                    }
                    // F05006：避難所開設状況
                    if (UserInfo.hasAuthz('F05006')) {
                        if ((UserInfo.getUserType() === UserType.MUNICIPALITY ||
                            UserInfo.getUserType() === UserType.OTHER_ORGAN) &&
                            UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                            this.shelterListLink.municipalityCd = this._municipalityCd;
                            mainMenuViewSubLinks.push(this.shelterListViewLink);
                        } else {
                            mainMenuViewSubLinks.push(this.shelterAdminViewLink);
                        }
                    }
                    // F05004：避難情報
                    if (UserInfo.hasAuthz('F05004')) {
                        if (UserInfo.getUserType() === UserType.MUNICIPALITY &&
                            UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                            this.evacorderAdminLink.municipalityCd = this._municipalityCd;
                            this.evacorderAdminLink.page = 'evacorder';
                        }
                        mainMenuViewSubLinks.push(this.evacorderAdminViewLink);
                    }
                    // F05005：避難情報発令判断支援
                    if (UserInfo.hasAuthz('F05005')) {
                        mainMenuViewSubLinks.push(this.evacRecommendLink);
                    }
                    if (mainMenuViewSubLinks.length > 0) {
                        links.push({ label: this.mainMenuLinksViewLabel, links: mainMenuViewSubLinks });
                    }
                }

                // F01001：監視ページ
                // if(UserInfo.hasAuthz('F01001')) {
                //     links.push(this.monitoringLink);
                // }

                // {
                //     var evacSubLinks = [];

                //     // F05004：避難情報
                //     if (UserInfo.hasAuthz('F05004')) {
                //         if (UserInfo.getUserType() === UserType.MUNICIPALITY &&
                //             UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                //             this.evacorderAdminLink.municipalityCd = this._municipalityCd;
                //             this.evacorderAdminLink.page = 'evacorder';
                //         }
                //         evacSubLinks.push(this.evacorderAdminLink);
                //     }
                //     // F05005：避難情報発令判断支援
                //     if (UserInfo.hasAuthz('F05005')) {
                //         evacSubLinks.push(this.evacRecommendLink);
                //     }

                //     if (evacSubLinks.length > 0) {
                //         links.push({ label: this.evacSubLinksLabel, links: evacSubLinks });
                //     }

                // }

                // 防災気象情報
                {
                    var wtrSubLinks = [];

                    // F03001：気象情報一覧
                    if (UserInfo.hasAuthz('F03001')) {
                        wtrSubLinks.push(this.weatherinfoLink);
                    }

                    // F03002：地震情報一覧
                    if (UserInfo.hasAuthz('F03002')) {
                        wtrSubLinks.push(this.earthquakeLink);
                    }

                    // F03002：津波情報一覧
                    if (UserInfo.hasAuthz('F03002')) {
                        wtrSubLinks.push(this.tsunamiLink);
                    }

                    // F03002：指定河川洪水予報
                    if (UserInfo.hasAuthz('F03002')) {
                        wtrSubLinks.push(this.floodforecastLink);
                    }

                    // F03001：気象リンク集
                    //                if(UserInfo.hasAuthz('F03001')) {
                    //                    wtrSubLinks.push(this.linkcollectionLink);
                    //                }

                    if (wtrSubLinks.length > 0) {
                        links.push({ label: this.wtrSubLinksLabel, links: wtrSubLinks });
                    }
                }

                // 観測情報等
                {
                    var obsSubLinks = [];

                    // // F04001：土砂災害危険度情報一覧
                    // if(UserInfo.hasAuthz('F04001')) {
                    //     obsSubLinks.push(this.sedimentriskobservationLink);
                    // }

                    // F04001 : 観測情報概況
                    //                if(UserInfo.hasAuthz('F04001')) {
                    //                    obsSubLinks.push(this.observationmapLink);
                    //                }

                    // F04001：雨量情報一覧
                    // if (UserInfo.hasAuthz('F04001')) {
                    //     obsSubLinks.push(this.rainfallobservationLink);
                    // }

                    // F04001：河川水位情報
                    if (UserInfo.hasAuthz('F04001')) {
                        obsSubLinks.push(this.riverlevelobservationLink);
                    }
                    // // F04001：流域雨量指数
                    // if (UserInfo.hasAuthz('F04001')) {
                    //     obsSubLinks.push(this.basinRainfallIndexLink);
                    // }
                    // F04001：危機管理型水位
                    // if (UserInfo.hasAuthz('F04001')) {
                    //     obsSubLinks.push(this.crisisManageRiverlevelObsLink);
                    // }

                    // F04001：市内気象情報
                    //                if(UserInfo.hasAuthz('F04001')) {
                    //                    obsSubLinks.push(this.localobservationLink);
                    //                }

                    // // F04001：流域平均雨量
                    // if(UserInfo.hasAuthz('F04001')) {
                    //     obsSubLinks.push(this.basinAveRainfallObservationLink);
                    // }

                    // F04001：潮位実況情報一覧
                    if (UserInfo.hasAuthz('F04001')) {
                        obsSubLinks.push(this.tidelevelobservationLink);
                    }

                    // F05004：避難情報発令判断支援
                    // if(UserInfo.hasAuthz('F05005')) {
                    //     obsSubLinks.push(this.evacRecommendLink);
                    // }

                    // F04001：ダム情報一覧
                    // if(UserInfo.hasAuthz('F04001')) {
                    //     obsSubLinks.push(this.damobservationLink);
                    // }

                    if (obsSubLinks.length > 0) {
                        links.push({ label: this.obsSubLinksLabel, links: obsSubLinks });
                    }
                }

                // 職員参集・緊急通知
                {
                    var convocationSubLinks = [];

                    // F08001：招集状況管理
                    if (UserInfo.hasAuthz('F08001')) {
                        // 職員参集管理
                        convocationSubLinks.push(this.convocationMailLink);
                    }
                    // 緊急通知
                    // var regexp = /^(?!.*-c).*(?=honbu).*$/;
                    // 「honbu」を含むユーザのみ権限を与える
                    var regexp = /^(?=.*honbu).*$/;
                    {
                        // var broadnotifyLink = [];
                        // FUNC_CDが振られたら修正
                        // ログインユーザのユーザIDの末尾に"honbu"が記述されていないならメニュー表示しない
                        // var regexp = /^(?!.*-c).*(?=honbu).*$/;
                        // if(UserInfo.hasAuthz('F12001')) {
                        if (regexp.test(UserInfo.getId()) || UserInfo.getId() === 'osaka-adm') {
                            convocationSubLinks.push(this.broadnotifyLink);
                            // convocationSubLinks.push(this.terminalLink);
                            // convocationSubLinks.push(this.broadnotifyGroupLink);
                            // }
                        }
                        // if(broadnotifyLink.length > 0) {
                        //     links.push({label: this.broadnotifyLinksLabel, links: broadnotifyLink});
                        // }
                    }
                    // F08002：職員招集設定管理
                    if (UserInfo.hasAuthz('F08002')) {
                        // 配信テンプレート管理
                        convocationSubLinks.push(this.convocationAutoMailLink);
                        // 配信グループ管理
                        convocationSubLinks.push(this.convocationGroupLink);
                        // 職員招集管理者管理
                        // convocationSubLinks.push(this.convocationUserLink);
                        // 職員情報(職員管理)
                        // convocationSubLinks.push(this.convocationEmployeeLink);
                        // （旧）職員参集情報管理
                        convocationSubLinks.push(this.convocationHistoryLink);
                    }
                    {
                        // var broadnotifyLink = [];
                        // FUNC_CDが振られたら修正
                        // ログインユーザのユーザIDの末尾に"honbu"が記述されていないならメニュー表示しない
                        // var regexp = /^(?!.*-c).*(?=honbu).*$/;
                        // if(UserInfo.hasAuthz('F12001')) {
                        if (regexp.test(UserInfo.getId()) || UserInfo.getId() === 'osaka-adm') {
                            // convocationSubLinks.push(this.broadnotifyLink);
                            convocationSubLinks.push(this.terminalLink);
                            convocationSubLinks.push(this.broadnotifyGroupLink);
                            // }
                        }
                    }

                    // F13001：指示共有グループ管理
                    if (UserInfo.hasAuthz('F13001')) {
                        convocationSubLinks.push(this.chatGroupLink);
                    }
                    // F13002：指示共有機能履歴管理
                    if (UserInfo.hasAuthz('F13002')) {
                        convocationSubLinks.push(this.chatHistoryLink);
                    }

                    if (convocationSubLinks.length > 0) {
                        links.push({ label: this.convocationSubLinksLabel, links: convocationSubLinks });
                    }
                }

                // ライフライン
                // {
                //     var lifelineSubLinks = [];
                //     // F05016：ライフライン
                //     if (UserInfo.hasAuthz('F05016')) {
                //         lifelineSubLinks.push(this.lifelineLink);
                //     }

                // if (lifelineSubLinks.length > 0) {
                //     links.push({ label: this.lifelineSubLinksLabel, links: lifelineSubLinks });
                // }
                // }

                // 外部連携等
                {
                    var disSubLinks = [];

                    // F06001：情報配信
                    //                if(UserInfo.hasAuthz('F06001')) {
                    //                    disSubLinks.push(this.provideinformationLink);
                    //                }

                    // F06001：防災ポータル情報配信
                    // if(UserInfo.hasAuthz('F06002')) {
                    //     disSubLinks.push(this.prefsitesendingLink);
                    // }
                    // F05011：通行規制情報取込
                    // FIXME一旦、お客様のGOが出るまでは非表示
                    // if(UserInfo.hasAuthz('F05011')) {
                    //     disSubLinks.push(this.trafficImportLink);
                    // }

                    // F05012：Spectee情報管理
                    // if (!this._lgwanFlg) {
                    if (UserInfo.hasAuthz('F05012')) {
                        disSubLinks.push(this.specteeLink);
                    }
                    if (UserInfo.hasAuthz('F06001')) {
                        disSubLinks.push(this.sendingHistoryLink);
                    }
                    // F05001：府連携・市本部内班間連携（クロノロジー）
                    if (UserInfo.hasAuthz('F05001')) {
                        disSubLinks.push(this.chronologyLink);
                    }
                    // F06006：市民への情報配信履歴
                    if (UserInfo.hasAuthz('F06006')) {
                        disSubLinks.push(this.citizenInfoHistoryLink);
                    }

                    // F06011：消防局情報閲覧
                    if (UserInfo.hasAuthz('F06011')) {
                        if(UserInfo.getRoleCd() === "R01021" || UserInfo.getRoleCd() === "R02032"){
                            if(UserInfo.getOrganization().deptCd === config.dept.fireDepartmentDeptCd){
                                disSubLinks.push(this.fdmaDisasterSituationLink);
                            }
                        }else{
                            disSubLinks.push(this.fdmaDisasterSituationLink);
                        }
                    } 


                    // F06010：水道局情報閲覧
                    if (UserInfo.hasAuthz('F06010')){
                        disSubLinks.push(this.waterworksLink);
                    }

                    // 物資支援システム
                    // disSubLinks.push(this.supplyrequestLink);
                    // }

                    if (disSubLinks.length > 0) {
                        links.push({ label: this.disSubLinksLabel, links: disSubLinks });
                    }
                }

                // 情報共有
                {
                    var infoShareLinks = [];
                    // 災害ポータル
                    infoShareLinks.push(this.disasterPortalLink);
                    // 地図
                    infoShareLinks.push(this.monitoringLink);
                    // 災害対応共有サイト
                    infoShareLinks.push(this.disasterInfoShareListLink);
                    // F13003：指示共有サイト
                    if (UserInfo.hasAuthz('F13003')) {
                        infoShareLinks.push(this.chatShareLink);
                    }
                    if (infoShareLinks.length > 0) {
                        links.push({ label: this.infoShareLinkLabel, links: infoShareLinks });
                    }
                }

                //            // 防災業務支援(管理)
                //             {
                //                 var supSubLinks = [];
                //
                //                 // F10001：施設管理
                //                 if(UserInfo.hasAuthz('F10001')) {
                //                     supSubLinks.push(this.facilityLink);
                //                 }
                //
                //                 // F04001：観測局一覧
                //                 if(UserInfo.hasAuthz('F04001')) {
                //                     supSubLinks.push(this.observationstationsLink);
                //                 }
                //
                //                 // F10003：利用者管理
                //                 if(UserInfo.hasAuthz('F10003')) {
                //                     supSubLinks.push(this.userLink);
                //                 }
                //
                //                 // F09002：フォルダ管理
                //                 if(UserInfo.hasAuthz('F09002')) {
                //                     supSubLinks.push(this.folderLink);
                //                 }
                //
                //                 if(supSubLinks.length > 0) {
                //                     links.push({label: this.supSubLinksLabel, links: supSubLinks});
                //                 }
                //             }
                // 帳票ディスプレイ
                {
                    var formDisplaySubLinks = [];

                    if (UserInfo.hasAuthz('F14001')) {
                        formDisplaySubLinks.push(this.formDisplayLink);
                    }
                    if (UserInfo.hasAuthz('F14002')) {
                        // formDisplaySubLinks.push(this.moveDisplayLink);
                        formDisplaySubLinks.push(this.displayLoginLink);
                    }

                    if (formDisplaySubLinks.length > 0) {
                        links.push({ label: this.formDisplaySubLinksLabel, links: formDisplaySubLinks });
                    }
                }
                // マスタ管理
                {
                    var masterSubLinks = [];

                    // F10001：避難所情報マスタ管理
                    if (UserInfo.hasAuthz('F10001')) {
                        masterSubLinks.push(this.facilityLink);
                    }
                    // F10005：施設情報マスタ管理
                    if (UserInfo.hasAuthz('F10005')) {
                        masterSubLinks.push(this.facilityMasterLink);
                    }
                    // F10003：利用者管理（本番モードのみ利用可）
                    if (UserInfo.hasAuthz('F10003') && !UserInfo.isTrainingFlg()) {
                        masterSubLinks.push(this.userLink);
                    }

                    // F04001：観測局管理
                    //                if(UserInfo.hasAuthz('F04001')) {
                    //                    masterSubLinks.push(this.observationstationsLink);
                    //                }

                    if (masterSubLinks.length > 0) {
                        links.push({ label: this.masterSubLinksLabel, links: masterSubLinks });
                    }
                }

                // FAQ
                links.push(this.questionsLink);

                // 災害シミュレーション
                if (UserInfo.hasAuthz('F12001') && UserInfo.isTrainingFlg()) {
                    links.push(this.simulationLink);
                }

                // その他
                // {
                    // var otherSubLinks = [];

                    // 掲示板画面
                    // if(UserInfo.hasAuthz('F07001')) {
                    //     otherSubLinks.push(this.bbsLink);
                    // }

                    // //災害シミュレーション
                    // if (UserInfo.hasAuthz('F12001') && UserInfo.isTrainingFlg()) {
                    //     otherSubLinks.push(this.simulationLink);
                    // }

                    // F09002：フォルダ管理
                    // if(UserInfo.hasAuthz('F09002')) {
                    //     otherSubLinks.push(this.folderLink);
                    // }



                    // 災害概況帳票出力
                    // otherSubLinks.push(this.disasterDialogLink);

                    // 防災リンク集
                    // otherSubLinks.push(this.dpLinkPageLink);

                    // よくある質問
                    // otherSubLinks.push(this.questionsLink);

                    // if (otherSubLinks.length > 0) {
                    //     links.push({ label: this.otherSubLinksLabel, links: otherSubLinks });
                    // }
                // }
                return links;
            },

            // メニュー作成処理
            initMenu: function () {
                this.inherited(arguments);

                var message = UserInfo.getId() + ' は';
                if (UserInfo.getRoleCd() === ACL.ADMIN_USER) {
                    message = message + '管理ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.SYSMGR_USER) {
                    message = message + '全体管理者です。';
                } else if (UserInfo.getRoleCd() === ACL.SHMGR_USER) {
                    message = message + '市本部管理者です。';
                } else if (UserInfo.getRoleCd() === ACL.XXMGR_USER) {
                    message = message + '所属管理者です。';
                } else if (UserInfo.getRoleCd() === ACL.SHIHONBU_USER) {
                    message = message + '市本部ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.KUHONBU_USER) {
                    message = message + '区本部ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.XXHONBU_USER) {
                    message = message + '所属本部ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.IPPAN_USER) {
                    message = message + '一般職員ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.AID_USER) {
                    message = message + '応援職員ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.VPD_USER) {
                    message = message + '自主防災組織ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.WEL_USER) {
                    message = message + '福祉避難所ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.FAC_USER) {
                    message = message + '施設管理者ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.SEW_USER) {
                    message = message + '下水処理事業者ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.ROA_USER) {
                    message = message + '道路管理事業者ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.REF_USER) {
                    message = message + '参照権限ユーザです。';
                } else {
                    message = message + '未定義ユーザです。 役割コード:' + UserInfo.getRoleCd();
                }
                console.debug(message);
                console.debug('ACL ADMIN : ' + UserInfo.getAcl().ADMIN);
                console.debug('ACL CHIEF ADMIN: ' + UserInfo.getAcl().CHIEF_ADMIN);

                // ユーザーの市町村コードをセット
                // 非ログイン時にgetMunicipalityCds()[0]でエラーが生じるのを回避
                if (UserInfo.getMunicipalityCd()) {
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                }

                // LGWAN側アクセスであるか
                // var runningMode = UserInfo.getRunningMode();
                // if (runningMode === 'LGWAN') {
                //     this._lgwanFlg = true;
                // }

                // リンク一覧を生成
                this._links = [];

                if (this.getMenuType() === this.MENU_TYPE_FLAT) {
                    // メニュータイプ：フラット型
                    this._links = this.createMenuTypeFlatLinks();
                } else if (this.getMenuType() === this.MENU_TYPE_HIERARCHY) {
                    // メニュータイプ：階層型
                    this._links = this.createMenuTypeHierarchyLinks();
                } else {
                    // メニュータイプ：階層型
                    this._links = this.createMenuTypeHierarchyLinks();
                    console.warn('メニュータイプが未定義です。 メニュータイプ:' + this.getMenuType());
                }

                this._links.push();
                this.updateLinks();
                this.own(Locator.on('change', lang.hitch(this, 'updateLinks')));
            }
        });
});
