import 'src/css';
import 'src/css/style.css';

// src/libにある "モジュールとして宣言されていない" 外部ライブラリを組み込む
import * as usng from './lib/usng.js?raw';
import * as gridlines from './lib/gridlines?raw';
import * as latlngLayer from './lib/latlng_layer?raw';
import * as utm from './lib/utm?raw';
import * as patternSrc from './lib/leaflet.pattern-src?raw';

define([
    'dojo/ready',
    'dojo/request/iframe',
    'leaflet',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/view/parser',
    'dojo/request/notify',
    'idis/control/Router',
    'idis/view/dialog/ConfirmDialog',
    // 以下、変数で受けないモジュール
    'dojo/has!webpack?dojo-webpack-plugin/amd/dojoES6Promise',
    'idis/util/polyfill',
    'app/control/AdisRouter',
    'app/control/AdisTrainingRouter',
    'app/view/Header',
    'app/view/TrainingHeader',
    'app/view/Menu'
], function (
    ready, iframe, L, Locator, UserInfo, parser, notify, Router, ConfirmDialog
) {

    eval.call(null, usng.default);
    eval.call(null, gridlines.default);
    eval.call(null, latlngLayer.default);
    eval.call(null, utm.default);
    eval.call(null, patternSrc.default);

    import(/* webpackChunkName: "MainPages" */ './MainPageModules').then(function() {
        // Leaflet 1.xのPolyfill（Leaflet.patternが依存）
        if (!L.DomUtil.remove) {
            L.DomUtil.remove = function (node) {
                if (node.parentNode) {
                    node.parentNode.removeChild(node);
                }
            };
        }
        // ファイルアップロード用のiframeを作成しておく
        require(['dojo/domReady'], function () {
            iframe.create();
        });
        // 履歴の監視開始
        Locator.start();
        // ユーザ情報を取得し、結果に関わらず画面をパースする
        UserInfo.load().always(function () {
            // leaflet-drawがAMD対応していないため、
            // leafletが確実にロードされてから画面をパースする。
            // ready登録時のpriority(=100)はparseOnLoadと同一。
            ready(100, parser, 'parse');
        });

        // API通信時の認証エラー（401）を検知し、ログイン画面に誘導する
        notify('error', function (error) {
            // ログイン画面は対象外
            if (error.response.status === 401 && Router.getCurrentPath()) {
                // 自動ログアウトのメッセージをダイアログで表示
                new ConfirmDialog({
                    title: '自動ログアウト',
                    content: '一定時間が経過した為、自動的にログアウトされました。<br>ログインしなおしてください。',
                    showCancel: false,
                    onOK: function () {
                        if (document.logout) {
                            // ログアウトフォームをサブミット
                            document.logout.submit();
                        }
                    }
                }).show();
            }
        });
    });
});
